import { createStore, createTypedHooks, State } from 'easy-peasy'
import {
  getRule as getRuleHTTP,
  listRules as listRulesHTTP,
  validateRule as validateRuleHTTP,
  createRuleSet as createRuleSetHTTP,
  updateRule as updateRuleHTTP,
  getRuleSet as getRuleSetHTTP,
} from '@signifyd/http'
import { ruleModel, RuleModel } from './rule'
import { simulationModel, SimulationModel } from './simulation'
import {
  predictionListEntriesModel,
  PredictionListEntriesModel,
} from './predictionList'

export interface StoreModel {
  ruleModel: RuleModel
  predictionListEntriesModel: PredictionListEntriesModel
  simulationModel: SimulationModel
}

export type StoreState = State<StoreModel>

export const storeModels = {
  ruleModel,
  predictionListEntriesModel,
  simulationModel,
}

const store = createStore<StoreModel>(storeModels, {
  injections: {
    getRuleHTTP,
    updateRuleHTTP,
    listRulesHTTP,
    validateRuleHTTP,
    createRuleSetHTTP,
    getRuleSetHTTP,
  },
})

export const { useStoreActions, useStoreDispatch, useStoreState } =
  createTypedHooks<StoreModel>()

export default store
