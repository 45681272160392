import {
  GetRulesParams,
  GET_RULES_ORDER_BY,
  listRules,
  RuleResponse,
  RULE_STAGE,
} from '@signifyd/http'
import { useQuery, DefinedUseQueryResult } from '@tanstack/react-query'
import { i18nInstance } from '@signifyd/components'
import { message } from 'antd'
import { AxiosError } from 'axios'
import { GET_RULES_LIMIT } from 'core/constants'
import { getHTTPErrorStatus } from 'core/http'
import { useDashboardPageFilters } from '../hooks/useDashboardPageFilters'

const ASCENDING_VALUE_BY_ORDER_BY = {
  [GET_RULES_ORDER_BY.SORT_RANK]: true,
  [GET_RULES_ORDER_BY.SORT_CREATED_AT]: false,
  [GET_RULES_ORDER_BY.SORT_SCHEDULE_START]: false,
  [GET_RULES_ORDER_BY.SORT_SCHEDULE_END]: false,
  [GET_RULES_ORDER_BY.SORT_HIT_COUNT]: false,
}

interface DashboardPolicies {
  publishedPolicies: Array<RuleResponse>
  otherPolicies: Array<RuleResponse>
  publishedCount: number
  otherCount: number
}
const useGetDashboardPolicies =
  (): DefinedUseQueryResult<DashboardPolicies> => {
    const [filters] = useDashboardPageFilters()
    const { teamId, checkpoint } = filters

    const publishedRulesQueryParams = {
      orderBy: filters.publishedOrderBy,
      ascending: ASCENDING_VALUE_BY_ORDER_BY[filters.publishedOrderBy],
      live: true,
      teamIds: teamId,
      limit: GET_RULES_LIMIT,
      checkpoints: checkpoint,
    } as GetRulesParams

    const otherRulesQueryParams = {
      orderBy: filters.othersOrderBy,
      ascending: ASCENDING_VALUE_BY_ORDER_BY[filters.othersOrderBy],
      live: false,
      stages: `${RULE_STAGE.NEW},${RULE_STAGE.PUBLISHED}`,
      teamIds: teamId,
      limit: GET_RULES_LIMIT,
      checkpoints: filters.checkpoint,
    } as GetRulesParams

    const getPolicies = async (): Promise<DashboardPolicies> => {
      const [
        {
          data: { data: publishedPolicies },
        },
        {
          data: { data: otherPolicies },
        },
      ] = await Promise.all([
        listRules(publishedRulesQueryParams),
        listRules(otherRulesQueryParams),
      ])

      return {
        publishedPolicies,
        otherPolicies,
        // TODO FET-1830 https://signifyd.atlassian.net/browse/FET-1830
        // Use data.meta.totalRows to get totalRows. Can't do currently due to BE bug
        publishedCount: publishedPolicies.length,
        otherCount: otherPolicies.length,
      }
    }

    return useQuery(
      ['dashboardPolicies', publishedRulesQueryParams, otherRulesQueryParams],
      getPolicies,
      {
        onError: (error) => {
          const status = getHTTPErrorStatus(
            error as AxiosError,
            i18nInstance.t('ruleCommon.fallbackErrorMessage.filterRules')
          )

          message.error({ content: status.error })
        },
        enabled: !!teamId && !!checkpoint,
        initialData: {
          publishedPolicies: [],
          otherPolicies: [],
          publishedCount: 0,
          otherCount: 0,
        },
      }
    )
  }

export default useGetDashboardPolicies
