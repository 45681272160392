import { useState, useEffect } from 'react'
import { useStoreState } from 'stores'

/**
 * Returns whether we should render the ListEntriesTable or a placeholder
 */
const useCanRenderTable = (): boolean => {
  // Note: this is a little complicated, it could be made simpler if the table handled
  // all the logic for displaying no results (instead of not showing the table at all)

  // Somehow I feel like this should return true if there are entries in the db even if we are filtering on the
  // frontend - the name seems to suggest that but that is not the case
  const hasAnyPredictionListEntries = useStoreState(
    (state) => state.predictionListEntriesModel.hasAnyPredictionListEntries
  )
  // For the initial load, we don't want to show the table then show no entries - but we don't
  // want to hide the table each time we search or filter
  const { pending } = useStoreState(
    (state) =>
      state.predictionListEntriesModel.HTTPStatuses.listPredictionListEntries
  )
  const searchTerm = useStoreState(
    (state) => state.predictionListEntriesModel.searchParams.searchTerm
  )
  const [initialLoadComplete, setInitialLoadComplete] = useState(false)

  useEffect(() => {
    if (!pending && (hasAnyPredictionListEntries || searchTerm)) {
      setInitialLoadComplete(true)
      // If the table is loaded and we delete all items
    } else if (
      initialLoadComplete &&
      !pending &&
      !searchTerm &&
      !hasAnyPredictionListEntries
    ) {
      setInitialLoadComplete(false)
    }
  }, [pending, hasAnyPredictionListEntries, initialLoadComplete, searchTerm])

  return initialLoadComplete || hasAnyPredictionListEntries
}

export default useCanRenderTable
