export default {
  publishedTab: 'Published ({{count}})',
  othersTab: 'Not published ({{count}})',
  publishSettingsBtn: 'Publishing settings',
  ruleSuccessModal: {
    createPolicy: {
      title: 'Success! Policy created.',
      ruleListLabel: 'NEW POLICY',
    },
    editPolicy: {
      title: 'Policy change was saved!',
      ruleListLabel: 'EDITED POLICY',
    },
    cancelText: 'View all policies',
    createNewRule: 'Create another policy',
    publishingSettings: 'Publishing settings',
  },
  ruleSuccessModalHelp: {
    title: 'Not sure what to do?',
    description1:
      '<bold>New</bold> policies are not yet impacting order traffic until published. Publish policy now or create more policies to publish together later.',
    description2:
      'Not ready to publish? Choose <bold>View all policies</bold> and find new policies in the <bold>Not to publish</bold> section.',
  },
  ruleDropdown: {
    viewSummary: 'View summary',
    duplicate: 'Duplicate',
    editRuleDetails: 'Edit details',
    deletePolicy: 'Delete policy',
    manageSchedule: 'Manage schedule',
    addSchedule: 'Add schedule',
    publishingDivider: 'PUBLISHING SETTINGS',
    moveToOthers: 'Move to <bold>Not published</bold>',
    moveToPublished: 'Move to <bold>Published</bold>',
    changeRank: 'Change rank',
  },
  ruleTable: {
    ruleDetailsModal: {
      title: 'Edit details',
      okText: 'Apply',
    },
    ruleScheduleModal: {
      addSchedule: 'Add schedule',
      manageSchedule: 'Manage schedule',
    },
    columnTitles: {
      rank: 'RANK',
      name: 'POLICY',
      action: 'ACTION',
      hits: 'HITS',
      schedule: 'START - END',
      status: 'STATUS',
    },
    emptyText: {
      published: 'No published policies',
      others: 'No other policies exist',
    },
    filters: {
      sortByLabel: 'Sort by',
      options: {
        rank: 'Rank',
        hits: 'Hits',
        createdDate: 'Created date',
        startDate: 'Start date',
        endDate: 'End date',
      },
    },
  },
  publishedRuleSetInfo: {
    lastPublished: 'Last published',
  },
  policyCheckpointForm: {
    main: {
      header: "Let's start with the customer's journey",
      description: 'Select when you want this policy to take effect',
    },
    actionButtonTitles: {
      checkout: 'Checkout',
      login: 'Login',
      return: 'Return',
    },
  },
}
