import { FC } from 'react'
import classnames from 'classnames/bind'
import { round } from 'lodash'
import moment from 'moment-timezone'
import { useTranslation } from 'react-i18next'
import { Point, ResponsiveLine, Serie } from '@nivo/line'
import { colorWhite, colorYonder, colorYonderLight20 } from '@signifyd/colors'
import { Text } from '@signifyd/components'
import {
  formatDate,
  formatTooltipDate,
  TIMEFRAME,
} from '../PolicyReports.utils'
import styles from './LineGraph.less'

const cx = classnames.bind(styles)

interface LineGraphProps {
  data: Array<Serie>
  timeframe: TIMEFRAME
  type: 'hitCount' | 'hitGmv'
}

const LineGraph: FC<LineGraphProps> = ({ data, timeframe, type }) => {
  const { t } = useTranslation()

  const startDate = data?.[0]?.data?.[0]?.x
  const endDate = data?.[0]?.data?.[data[0].data.length - 1]?.x

  const getHits = (point: Point): string =>
    round(Number(point.data.y)).toLocaleString('en', {
      useGrouping: true,
    })

  const {
    right,
    left,
    key,
  }: { right: number; left: number; key: 'hitsByCount' | 'hitsByGmv' } =
    type === 'hitCount'
      ? { right: 60, left: 40, key: 'hitsByCount' }
      : { right: 40, left: 60, key: 'hitsByGmv' }

  return (
    <>
      <ResponsiveLine
        data={data}
        margin={{
          top: 5,
          bottom: 5,
          right,
          left,
        }}
        colors={[colorYonder, colorYonderLight20]}
        theme={{
          axis: {
            domain: {
              line: {
                stroke: colorYonder,
                strokeWidth: 1,
              },
            },
            ticks: {
              line: { stroke: colorYonder, strokeWidth: 1 },
            },
          },
        }}
        curve="linear"
        enableArea
        enableGridX
        enableGridY={false}
        animate
        crosshairType="x"
        pointSize={5}
        pointColor={colorWhite}
        pointBorderColor={colorYonder}
        pointBorderWidth={2}
        useMesh
        tooltip={({ point }) => {
          const hits = getHits(point)
          const isFirstPoint =
            data?.[0]?.data.findIndex((datum) => datum.x === point.data.x) === 0
          const isLastPoint =
            data?.[0]?.data.findIndex((datum) => datum.x === point.data.x) ===
            data[0].data.length - 1

          return (
            <div className={styles.tooltip}>
              <div>
                {formatTooltipDate(
                  moment(point.data.x).utc(),
                  timeframe,
                  isFirstPoint,
                  isLastPoint
                )}
              </div>
              <div className={styles.hits}>
                {t(`ruleSummaryPage.policyReports.${key}.label.CHECKOUT`, {
                  context: 'hits',
                  hits,
                })}
              </div>
            </div>
          )
        }}
        axisBottom={{
          tickValues: [
            data[0].data[0].x,
            data[0].data[data[0].data.length - 1].x,
          ],
        }}
        axisLeft={{
          tickValues: 5,
          format: (value) => {
            if (value === 0) {
              return ''
            }

            return type === 'hitGmv' ? `$${value}` : value
          },
        }}
        yScale={
          !data[0].data.find(({ y }) => !!y)
            ? { type: 'linear', min: 0, max: type === 'hitCount' ? 50 : 500 }
            : undefined
        }
      />
      {startDate && endDate && (
        <div
          className={cx('xAxisDateRange', `xAxisDateRange-${type}${timeframe}`)}
        >
          <Text size="xs" data-test-id={`${type}ChartStartDate`}>
            {formatDate(moment(startDate).utc(), timeframe)}
          </Text>
          <Text size="xs" data-test-id={`${type}ChartEndDate`}>
            {formatDate(moment(endDate).utc().endOf('hour'), timeframe, true)}
          </Text>
        </div>
      )}
    </>
  )
}

export default LineGraph
