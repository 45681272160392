import { FC, useState } from 'react'
import { Input, Button } from 'antd'
import { PredictionList } from '@signifyd/http'
import { useTranslation } from 'react-i18next'
import { Text } from '@signifyd/components'
import { GetListEntriesFilters } from 'stores/predictionList'
import DeleteListEntryPopconfirm from 'pages/ListDetailsPage/components/DeleteListEntryPopconfirm'
import { useStoreState } from 'stores'
import { useListDetailsPageQueryParams } from 'pages/ListDetailsPage/useListDetailsPageQueryParams'
import styles from './ListEntriesTable.less'

const { Search } = Input

export interface Props {
  predictionList: PredictionList
  filters: GetListEntriesFilters
  onDeleteSelectedEntries: () => void
}

const ListEntriesTableHeader: FC<Props> = ({
  filters,
  predictionList,
  onDeleteSelectedEntries,
}) => {
  const [searchValue, setSearchValue] = useState(() => filters.searchTerm || '')
  const { t } = useTranslation()

  const [, setQuery] = useListDetailsPageQueryParams()

  const onSearch = (value: string): void => {
    setQuery({
      searchTerm: value,
      page: 0,
    })
  }

  const selectedEntryIds = useStoreState(
    (state) => state.predictionListEntriesModel.selectedEntryIds
  )

  return (
    <div className={styles.tableHeader}>
      <Search
        value={searchValue}
        allowClear
        className={styles.search}
        placeholder={t(
          `listDetailsPage.listEntriesTable.searchPlaceholder.${predictionList.type}`
        )}
        onSearch={onSearch}
        onChange={(e) => setSearchValue(e.target.value)}
        data-test-id="list-entries-table-search"
      />
      <div className={styles.tableHeaderButton}>
        <Button
          type="primary"
          disabled={!searchValue.length}
          onClick={() => onSearch(searchValue)}
          data-test-id="listEntriesTableBulkSearchButton"
          data-analytics-id="list-entries-table-bulk-search-button"
        >
          {t('listDetailsPage.listEntriesTable.searchButton')}
        </Button>
      </div>
      <div className={styles.tableHeaderButton}>
        <DeleteListEntryPopconfirm
          count={selectedEntryIds.length}
          placement="right"
          onConfirm={onDeleteSelectedEntries}
        >
          <Button
            type="primary"
            disabled={!selectedEntryIds.length}
            data-test-id="list-entries-table-bulk-delete-button"
          >
            {t(
              'listDetailsPage.listEntriesTable.deleteListEntryPopconfirm.bulkDeleteEntriesBtn',
              {
                count: selectedEntryIds.length,
              }
            )}
          </Button>
        </DeleteListEntryPopconfirm>
      </div>
      <div className={styles.selectionTextWrapper}>
        {selectedEntryIds.length > 0 && (
          <Text>
            {t('listDetailsPage.listEntriesTable.selections.count', {
              count: selectedEntryIds.length,
            })}
          </Text>
        )}
      </div>
    </div>
  )
}

export default ListEntriesTableHeader
