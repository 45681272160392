import { FC, useEffect } from 'react'
import moment from 'moment-timezone'
import {
  AppAnalytics,
  AppConfigProvider,
  ErrorBoundary,
  Layout,
  ShadowUserBanner,
  useAppCues,
  useUserContext,
  QAInfoPopover,
} from '@signifyd/components'
import { decisionCenterTokens } from '@signifyd/sig-keys'
import { theme } from '@signifyd/ant'
import useUserTeams from 'core/hooks/useUserTeams/useUserTeams'
import AppRoutes from './AppRoutes'

const AppContainer: FC = () => {
  const { user, userConfigs } = useUserContext()
  const { userTimeZone } = useUserTeams()

  useAppCues({
    user,
    appName: 'decision-center',
    allowedBuildEnvs: window.isRegressionTest ? [] : ['staging', 'production'],
    buildEnv: process.env.BUILD_ENV,
  })

  useEffect(() => {
    if (userTimeZone) {
      moment.tz.setDefault(userTimeZone)
    }
  }, [userTimeZone])

  return (
    <AppConfigProvider
      theme={theme}
      localization={{ appName: 'decision-center' }}
      user={user}
      userConfigs={userConfigs}
    >
      <ErrorBoundary>
        <Layout>
          <AppAnalytics
            enabled={process.env.BUILD_ENV === 'production'}
            user={user}
            heapAppId={decisionCenterTokens.heapAppId}
            logRocketId={decisionCenterTokens.logRocketId}
          />
          <AppRoutes />
          <ShadowUserBanner currentUser={user} />
          <QAInfoPopover />
        </Layout>
      </ErrorBoundary>
    </AppConfigProvider>
  )
}

export default AppContainer
