import { FC } from 'react'
import { message } from 'antd'
import { enableMapSet } from 'immer'
import { BrowserRouter } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import {
  UserProvider,
  defaultQueryClientConfig,
  initLocalization,
} from '@signifyd/components'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { NAV_BAR_HEIGHT, SPACE_MD } from 'core/constants'
import locales from 'locales'
import AppContainer from './AppContainer'

message.config({
  top: NAV_BAR_HEIGHT + SPACE_MD,
  maxCount: 1,
})

enableMapSet()

initLocalization(locales)

const queryClient = new QueryClient(defaultQueryClientConfig)

const App: FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <UserProvider>
            <AppContainer />
          </UserProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryParamProvider>
      </BrowserRouter>
    </QueryClientProvider>
  )
}

export default App
