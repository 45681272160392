import { PREDICTION_LIST_SORT_BY } from '@signifyd/http'

export enum LIST_PAGE_SORT_BY_FILTER {
  LAST_UPDATE = 'LAST_UPDATE',
  LIST_NAME_A_TO_Z = 'LIST_NAME_A_TO_Z',
  LIST_NAME_Z_TO_A = 'LIST_NAME_Z_TO_A',
  NUMBER_OF_ITEMS = 'NUMBER_OF_ITEMS',
  NUMBER_OF_RULES = 'NUMBER_OF_RULES',
}

export enum LIST_PAGE_TYPE_FILTER {
  ALL = 'ALL',
  CARD_BIN = 'CARD_BIN',
  DEVICE_ID = 'DEVICE_ID',
  EMAIL = 'EMAIL',
  IP_ADDRESS = 'IP_ADDRESS',
  ITEM_ID = 'ITEM_ID',
  DISCOUNT_CODE = 'DISCOUNT_CODE',
  PHONE_NUMBER = 'PHONE_NUMBER',
  ADDRESS = 'ADDRESS',
  EMAIL_DOMAIN = 'EMAIL_DOMAIN',
  MEMBERSHIP_ID = 'MEMBERSHIP_ID',
  ACCOUNT_NUMBER = 'ACCOUNT_NUMBER',
  TAX_ID = 'TAX_ID',
}

export enum LIST_PAGE_EXPIRATION_FILTER {
  ALL = 'ALL',
  YES = 'YES',
  NO = 'NO',
}

export const MAP_SORT_BY_FILTER_TO_PARAMS = {
  [LIST_PAGE_SORT_BY_FILTER.LAST_UPDATE]: {
    orderBy: PREDICTION_LIST_SORT_BY.SORT_UPDATED_AT,
    ascending: false,
  },
  [LIST_PAGE_SORT_BY_FILTER.LIST_NAME_A_TO_Z]: {
    orderBy: PREDICTION_LIST_SORT_BY.SORT_NAME,
    ascending: true,
  },
  [LIST_PAGE_SORT_BY_FILTER.LIST_NAME_Z_TO_A]: {
    orderBy: PREDICTION_LIST_SORT_BY.SORT_NAME,
    ascending: false,
  },
  [LIST_PAGE_SORT_BY_FILTER.NUMBER_OF_ITEMS]: {
    orderBy: PREDICTION_LIST_SORT_BY.SORT_NUMBER_OF_ENTRIES,
    ascending: false,
  },
  [LIST_PAGE_SORT_BY_FILTER.NUMBER_OF_RULES]: {
    orderBy: PREDICTION_LIST_SORT_BY.SORT_NUMBER_OF_RULES,
    ascending: false,
  },
}

export const MAP_HAS_EXPIRATION_FILTER_TO_PARAMS = {
  [LIST_PAGE_EXPIRATION_FILTER.ALL]: {
    hasExpiration: undefined,
  },
  [LIST_PAGE_EXPIRATION_FILTER.YES]: {
    hasExpiration: true,
  },
  [LIST_PAGE_EXPIRATION_FILTER.NO]: {
    hasExpiration: false,
  },
}
