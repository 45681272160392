import { ReactNode } from 'react'
import { ColumnProps } from 'antd/es/table'
import { SortOrder } from 'antd/es/table/interface'
import {
  PredictionListEntry,
  PREDICTION_LIST_ENTRY_SORT_BY,
} from '@signifyd/http'
import { Tooltip } from 'antd'
import { i18nInstance } from '@signifyd/components'
import { QuestionCircleOutlined } from '@ant-design/icons'
import ListEntryDropdown from 'pages/ListDetailsPage/components/ListEntryDropdown'
import FormatDate from 'core/components/FormatDate'
import styles from './ListEntriesTable.less'

type OnDeleteEntry = (id: number) => void

export enum DATA_INDEX {
  value = 'value',
  createdAt = 'createdAt',
  createdByName = 'createdByName',
  id = 'id',
}

export const sorterToOrderBy = {
  createdAt: PREDICTION_LIST_ENTRY_SORT_BY.SORT_CREATED_AT,
  createdByName: PREDICTION_LIST_ENTRY_SORT_BY.SORT_CREATED_BY_NAME,
}

const renderIcon =
  (onDeleteEntry: OnDeleteEntry) =>
  (id: number): ReactNode => {
    return <ListEntryDropdown onClickDelete={() => onDeleteEntry(id)} />
  }

const renderCreatedAt =
  (userTimeZone: string) =>
  (createdAt: string): ReactNode => {
    return <FormatDate UTCTimestamp={createdAt} timeZone={userTimeZone} />
  }

interface Props {
  title: {
    value: string
    createdAt: string
    createdByName: string
  }
  orderBy: PREDICTION_LIST_ENTRY_SORT_BY | null
  ascending: boolean | null
  onDeleteEntry: OnDeleteEntry
  userTimeZone: string
}

const getColumns = ({
  title,
  orderBy,
  ascending,
  onDeleteEntry,
  userTimeZone,
}: Props): Array<ColumnProps<PredictionListEntry>> => {
  let colAscending: SortOrder | undefined
  if (ascending) {
    colAscending = 'ascend'
  } else if (ascending === false) {
    colAscending = 'descend'
  }

  return [
    {
      title: () => {
        return (
          <div className={styles.listTypeContainer}>
            {title.value}
            <Tooltip
              placement="topLeft"
              title={i18nInstance.t(
                'listDetailsPage.listEntriesTable.listTypeTooltip'
              )}
              arrow={{
                pointAtCenter: true,
              }}
            >
              <QuestionCircleOutlined
                data-test-id="listTypeTooltipIcon"
                className={styles.listTypeTooltipIcon}
              />
            </Tooltip>
          </div>
        )
      },
      dataIndex: DATA_INDEX.value,
      key: DATA_INDEX.value,
    },
    {
      title: title.createdAt,
      dataIndex: DATA_INDEX.createdAt,
      key: DATA_INDEX.createdAt,
      sorter: true,
      sortOrder:
        orderBy === PREDICTION_LIST_ENTRY_SORT_BY.SORT_CREATED_AT
          ? colAscending
          : undefined,
      render: renderCreatedAt(userTimeZone),
    },
    {
      title: title.createdByName,
      dataIndex: DATA_INDEX.createdByName,
      key: DATA_INDEX.createdByName,
      sorter: true,
      sortOrder:
        orderBy === PREDICTION_LIST_ENTRY_SORT_BY.SORT_CREATED_BY_NAME
          ? colAscending
          : undefined,
    },
    {
      key: 'dropdown',
      align: 'right',
      dataIndex: DATA_INDEX.id,
      render: renderIcon(onDeleteEntry),
    },
  ]
}

export default getColumns
