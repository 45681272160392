import { useEffect, FC } from 'react'
import { useParams } from 'react-router-dom'
import { useStoreActions } from 'stores'
import ListDetailsPageContainer from './containers/ListDetailsPageContainer'
import { getStoreFiltersFromSearchParams } from './ListDetailsPage.utils'
import { useListDetailsPageQueryParams } from './useListDetailsPageQueryParams'

const ListDetailsPage: FC = () => {
  const { listId } = useParams()
  const [query] = useListDetailsPageQueryParams()

  const { listPredictionListEntries, resetState: resetListEntriesState } =
    useStoreActions((actions) => actions.predictionListEntriesModel)

  // move this to predictionListEntries store
  useEffect(() => {
    if (!listId) {
      return
    }

    const filters = getStoreFiltersFromSearchParams(query)

    if (filters) {
      listPredictionListEntries({ listId, filters })
    }
  }, [listPredictionListEntries, listId, query])

  useEffect(() => {
    return () => {
      resetListEntriesState()
    }
  }, [resetListEntriesState])

  if (!listId) {
    return null
  }

  return <ListDetailsPageContainer listId={+listId} />
}

export default ListDetailsPage
