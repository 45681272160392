import { FC, useState } from 'react'
import { Alert, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { Space, T3, Text, useUserContext } from '@signifyd/components'
import { CHECKPOINT } from '@signifyd/http'
import useGetPolicyReport from 'pages/PolicySummaryPage/queries/useGetPolicyReport'
import HitReport from './HitReport/HitReport'
import TextTooltip from './TextTooltip/TextTooltip'
import {
  TIMEFRAME,
  getPolicyReportDateRange,
  getTimeframeOptions,
} from './PolicyReports.utils'
import styles from './PolicyReports.less'
import ErrorComponent from './ErrorComponent/ErrorComponent'
import ViewAffectedOrdersButton from './ViewAffectedOrdersButton/ViewAffectedOrdersButton'

interface PolicyReportsProps {
  policyId: number
  checkpoint: Exclude<CHECKPOINT, CHECKPOINT.LOGIN>
}

const PolicyReports: FC<PolicyReportsProps> = ({ policyId, checkpoint }) => {
  const { t } = useTranslation()

  const [timeframe, setTimeframe] = useState(TIMEFRAME['30D'])
  const { data, isLoading, isError } = useGetPolicyReport(
    policyId,
    checkpoint,
    timeframe
  )
  const {
    user: {
      features: { POWER_SEARCH },
    },
  } = useUserContext()

  const isPowerSearchEnabled = !!POWER_SEARCH?.featureFlag

  return (
    <div data-test-id="policyReports">
      <div>
        <Text className={styles.timeframeLabel} size="sm">
          {t('ruleSummaryPage.policyReports.timeframe.label')}
        </Text>
        <Select
          className={styles.timeframeSelect}
          value={timeframe}
          onChange={setTimeframe}
        >
          {getTimeframeOptions().map(({ value, label }) => {
            return (
              <Select.Option key={value} value={value} title={label}>
                {label}
              </Select.Option>
            )
          })}
        </Select>
      </div>
      <Space size="sm" />
      <Alert
        className={styles.timezoneMessage}
        data-test-id="timezoneMessage"
        message={
          <TextTooltip
            block
            checkpoint={checkpoint}
            field="timezone"
            size="md"
          />
        }
        type="info"
      />
      <Space size="lg" />
      <T3 className={styles.title}>
        <div>{t(`ruleSummaryPage.policyReports.title.${checkpoint}`)}</div>
        <ViewAffectedOrdersButton
          checkpoint={checkpoint}
          policyId={policyId}
          dateRange={getPolicyReportDateRange(timeframe)}
          isPowerSearchEnabled={isPowerSearchEnabled}
        />
      </T3>
      <Space size="sm" />
      {isError ? (
        <ErrorComponent />
      ) : (
        <div className={styles.reportContainer}>
          <HitReport
            checkpoint={checkpoint}
            data={data}
            isLoading={isLoading}
            timeframe={timeframe}
            type="Count"
          />
          <HitReport
            checkpoint={checkpoint}
            data={data}
            isLoading={isLoading}
            timeframe={timeframe}
            type="Gmv"
          />
        </div>
      )}
      <Space size="lg" />
    </div>
  )
}

export default PolicyReports
