import { FunctionComponent } from 'react'
import { CHECKPOINT_ACTION } from '@signifyd/http'
import { useTranslation } from 'react-i18next'
import { getCheckpointActionData } from '@signifyd/components'

interface Props {
  action: CHECKPOINT_ACTION | null
}

const RuleRecommendedActionDisplay: FunctionComponent<Props> = ({ action }) => {
  const { t } = useTranslation()

  return (
    action && (
      <span
        style={{
          color: getCheckpointActionData()[action].textColor,
        }}
      >
        {t(`checkpointAction.${action}`)}
      </span>
    )
  )
}

export default RuleRecommendedActionDisplay
