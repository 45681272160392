import { Serie } from '@nivo/line'
import { i18nInstance } from '@signifyd/components'
import { PolicyReportResponse } from '@signifyd/http'
import moment, { Moment } from 'moment-timezone'

export enum TIMEFRAME {
  '24H' = '24H',
  '7D' = '7D',
  '30D' = '30D',
  '90D' = '90D',
  '180D' = '180D',
  '365D' = '365D',
}

const timeframeKey = 'ruleSummaryPage.policyReports.timeframe'
const { t } = i18nInstance

export const getTimeframeOptions = (): Array<{
  value: string
  label: string
}> => {
  const TIMEFRAME_OPTIONS = [
    {
      value: TIMEFRAME['24H'],
      label: t(`${timeframeKey}.24H`),
    },
    {
      value: TIMEFRAME['7D'],
      label: t(`${timeframeKey}.7D`),
    },
    {
      value: TIMEFRAME['30D'],
      label: t(`${timeframeKey}.30D`),
    },
    {
      value: TIMEFRAME['90D'],
      label: t(`${timeframeKey}.90D`),
    },
    {
      value: TIMEFRAME['180D'],
      label: t(`${timeframeKey}.180D`),
    },
    {
      value: TIMEFRAME['365D'],
      label: t(`${timeframeKey}.365D`),
    },
  ]

  return TIMEFRAME_OPTIONS
}

export const convertPolicyHistorytoChartData = (
  data: PolicyReportResponse,
  type: 'Count' | 'Gmv'
): Array<Serie> => {
  return [
    {
      id: `hit${type}`,
      data: data.history
        .map((item) => ({
          x: item.timestamp,
          y: item[`hit${type}`],
        }))
        .sort((a, b) => new Date(a.x).getTime() - new Date(b.x).getTime()),
    },
  ]
}

export const getStartDate = (endDate: Moment, timeframe: TIMEFRAME): Moment => {
  const date = endDate.clone()

  switch (timeframe) {
    case TIMEFRAME['24H']:
      return date.subtract(1, 'days').startOf('hour')
    case TIMEFRAME['7D']:
      return date.subtract(7, 'days').startOf('day')
    case TIMEFRAME['30D']:
      return date.subtract(30, 'days').startOf('day')
    case TIMEFRAME['90D']:
      return date.subtract(90, 'days').startOf('day')
    case TIMEFRAME['180D']:
      return date.subtract(180, 'days').startOf('day')
    case TIMEFRAME['365D']:
      return date.subtract(365, 'days').startOf('day')
    default:
      return date.startOf('day')
  }
}

export const getPolicyReportDateRange = (
  timeframe: TIMEFRAME
): [string, string] => {
  const endDate = moment().utc()

  const startDate = getStartDate(endDate, timeframe)

  return [startDate.toISOString(), endDate.toISOString()]
}

export const formatDate = (
  date: Moment,
  timeframe: TIMEFRAME,
  isLastDate = false
): string => {
  let endDate = moment(date)
  const now = moment(moment.now())

  switch (timeframe) {
    case TIMEFRAME['24H']:
      return date.format('MMM[.] D[\n]h:mmA z')
    case TIMEFRAME['7D']:
    case TIMEFRAME['30D']:
      return date.format("MMM[. ]D,[\n']YY")
    case TIMEFRAME['90D']:
      // tooltip for 90 day timeframe should display 1 week range, i.e. Mar. 1 - 7, 8 - 14, etc.
      endDate = endDate.add(6, 'days')

      if (endDate.isSameOrAfter(now)) {
        endDate = now
      }

      return `${date.format('MMM[.] D')} - ${endDate.format("D,[\n']YY")}`
    case TIMEFRAME['180D']:
      // 180 day timeframe should display 2 week range
      endDate = endDate.add(13, 'days')

      if (endDate.isSameOrAfter(now)) {
        endDate = now
      }

      return `${date.format('MMM[.] D')} - ${endDate.format("D,[\n']YY")}`
    case TIMEFRAME['365D']:
      return isLastDate
        ? now.format("MMM[. ]D,[\n']YY")
        : date.format("MMM[. ]D,[\n']YY")
    default:
      return ''
  }
}

export const formatTooltipDate = (
  date: Moment,
  timeframe: TIMEFRAME,
  isFirstDate = false,
  isLastDate = false
): string => {
  let endDate = date.clone()
  const now = moment(moment.now())

  switch (timeframe) {
    case TIMEFRAME['24H']:
      return date.format("MMM[.] D,[ ']YY h[:00-]h[:59]A z")
    case TIMEFRAME['7D']:
    case TIMEFRAME['30D']:
      return date.format("MMM[. ]D,[ ']YY")
    case TIMEFRAME['90D']:
      // tooltip for 90 day timeframe should display 1 week range, i.e. Mar. 1 - 7, 8 - 14, etc.
      endDate = endDate.add(6, 'days')

      if (endDate.isSameOrAfter(now)) {
        endDate = now
      }

      return `${date.format("MMM[.] D, [']YY")} - ${endDate.format(
        "MMM[.] D, [']YY"
      )}`
    case TIMEFRAME['180D']:
      // 180 day timeframe should display 2 week range
      endDate = endDate.add(13, 'days')

      if (endDate.isSameOrAfter(now)) {
        endDate = now
      }

      return `${date.format("MMM[.] D, [']YY")} - ${endDate.format(
        "MMM[.] D, [']YY"
      )}`
    case TIMEFRAME['365D']:
      if (isFirstDate) {
        endDate = endDate.endOf('month')

        return `${date.format('MMM[.] D')} - ${endDate.format(
          "MMM[.] D, [']YY"
        )}`
      }

      if (isLastDate) {
        date = date.startOf('month')

        return `${date.format('MMM[.] D')} - ${now.format("MMM[.] D, [']YY")}`
      }

      return date.format("MMMM [']YY")
    default:
      return ''
  }
}
