import { memo, useState, useMemo, FC } from 'react'
import { message, Table } from 'antd'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RuleResponse, deletePolicy, updateRule } from '@signifyd/http'
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import { i18nInstance } from '@signifyd/components'
import DeletePolicyModal from 'core/components/DeletePolicyModal/DeletePolicyModal'
import RuleScheduleModal from 'core/components/RuleScheduleModal'
import useUserTeams from 'core/hooks/useUserTeams'
import { getHTTPErrorStatus } from 'core/http'
import { PolicyDetails } from 'pages/DashboardPage/components/PolicyDetailsForm/PolicyDetailsForm'
import { POLICY_GROUP_TYPE } from 'pages/DashboardPage/DashboardPage.types'
import useGetDashboardPolicies from 'pages/DashboardPage/queries/useGetDashboardPolicies'
import EditPolicyModal from '../EditPolicyModal'
import getColumns from './PolicyTable.config'
import styles from './PolicyTable.less'

type UpdatedPolicyPayload = Partial<RuleResponse> & {
  ruleId: number
}

const useUpdatePolicyInDashboard = (): UseMutationResult<
  void,
  unknown,
  UpdatedPolicyPayload
> => {
  const queryClient = useQueryClient()

  return useMutation(
    async (updatedPolicy) => {
      const { ruleId, ...rule } = updatedPolicy

      await updateRule(ruleId, rule)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['dashboardPolicies'])
      },
      onError: (error) => {
        const status = getHTTPErrorStatus(
          error as AxiosError,
          i18nInstance.t('ruleCommon.fallbackErrorMessage.updateRule')
        )

        message.error({ content: status.error })
      },
    }
  )
}

export const useDeletePolicyInDashboard = (): UseMutationResult<
  void,
  unknown,
  number
> => {
  const queryClient = useQueryClient()

  return useMutation(
    async (ruleId: number) => {
      await deletePolicy(ruleId)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['dashboardPolicies'])
      },
      onError: (error) => {
        const status = getHTTPErrorStatus(
          error as AxiosError,
          i18nInstance.t('ruleCommon.fallbackErrorMessage.deletePolicy')
        )

        message.error({ content: status.error })
      },
    }
  )
}

interface Props {
  policyGroupType: POLICY_GROUP_TYPE
}

const PolicyTable: FC<Props> = ({ policyGroupType }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [selectedRule, setSelectedRule] = useState<RuleResponse | null>(null)
  const [ruleDetailsModalVisible, setRuleDetailsModalVisible] = useState(false)
  const [ruleScheduleModalVisible, setRuleScheduleModalVisible] =
    useState(false)
  const [ruleDeletePolicyModalVisible, setRuleDeletePolicyModalVisible] =
    useState(false)

  const { userTimeZone } = useUserTeams()

  const {
    data: { publishedPolicies, otherPolicies },
    isFetching: policiesAreLoading,
  } = useGetDashboardPolicies()

  const visibleRules =
    policyGroupType === POLICY_GROUP_TYPE.published
      ? publishedPolicies
      : otherPolicies

  const tableLocale = useMemo(
    () => ({
      emptyText:
        policyGroupType === POLICY_GROUP_TYPE.published
          ? t('ruleDashboardPage.ruleTable.emptyText.published')
          : t('ruleDashboardPage.ruleTable.emptyText.others'),
    }),
    [t, policyGroupType]
  )

  const columns = useMemo(
    () =>
      getColumns({
        titles: {
          rank: t('ruleDashboardPage.ruleTable.columnTitles.rank'),
          name: t('ruleDashboardPage.ruleTable.columnTitles.name'),
          action: t('ruleDashboardPage.ruleTable.columnTitles.action'),
          hits: t('ruleDashboardPage.ruleTable.columnTitles.hits'),
          schedule: t('ruleDashboardPage.ruleTable.columnTitles.schedule'),
          status: t('ruleDashboardPage.ruleTable.columnTitles.status'),
        },
        policyGroupType,
        onClickEditRuleDetails: (rule) => {
          setSelectedRule(rule)
          setRuleDetailsModalVisible(true)
        },
        onClickDeletePolicyDetails: (rule) => {
          setRuleDeletePolicyModalVisible(true)
          setSelectedRule(rule)
        },
        onClickManageSchedule: (rule) => {
          setSelectedRule(rule)
          setRuleScheduleModalVisible(true)
        },
      }),
    [policyGroupType, t]
  )

  const updatePolicy = useUpdatePolicyInDashboard()

  const deletePolicy = useDeletePolicyInDashboard()

  const onSubmit = async ({
    name,
    description,
  }: PolicyDetails): Promise<void> => {
    if (!selectedRule) {
      return
    }

    await updatePolicy.mutateAsync({
      name,
      description,
      ruleId: selectedRule.ruleId,
    })

    setSelectedRule(null)
    setRuleDetailsModalVisible(false)
  }

  if (!visibleRules) {
    return null
  }

  const ruleScheduleModalTitle =
    selectedRule?.scheduleStart || selectedRule?.scheduleEnd
      ? t('ruleDashboardPage.ruleTable.ruleScheduleModal.manageSchedule')
      : t('ruleDashboardPage.ruleTable.ruleScheduleModal.addSchedule')

  return (
    <>
      <Table
        locale={tableLocale}
        loading={policiesAreLoading}
        size="middle"
        pagination={false}
        dataSource={visibleRules}
        rowKey="ruleId"
        columns={columns}
        rowClassName={styles.row}
        onRow={(record) => ({
          onClick: () => {
            navigate(`/policies/summary/${record.ruleId}`, {
              state: { backToPreviousPage: true },
            })
          },
        })}
      />
      {selectedRule && (
        <>
          <EditPolicyModal
            policyToEdit={selectedRule}
            visible={ruleDetailsModalVisible}
            onCancel={() => {
              setSelectedRule(null)
              setRuleDetailsModalVisible(false)
            }}
            onSubmit={onSubmit}
          />
          <DeletePolicyModal
            policyId={selectedRule.ruleId}
            isVisible={ruleDeletePolicyModalVisible}
            onCancel={() => setRuleDeletePolicyModalVisible(false)}
            onDelete={async () => {
              const { ruleId } = selectedRule

              await deletePolicy.mutateAsync(ruleId)

              setRuleDeletePolicyModalVisible(false)
            }}
          />
        </>
      )}

      <RuleScheduleModal
        loading={updatePolicy.isLoading}
        visible={ruleScheduleModalVisible && !!selectedRule}
        userTimeZone={userTimeZone}
        title={ruleScheduleModalTitle}
        rule={selectedRule}
        onCancel={() => {
          setSelectedRule(null)
          setRuleScheduleModalVisible(false)
        }}
        onSubmit={async ({ scheduleStart, scheduleEnd }) => {
          if (!selectedRule) {
            return
          }

          await updatePolicy.mutateAsync({
            scheduleStart,
            scheduleEnd,
            ruleId: selectedRule.ruleId,
          })

          setSelectedRule(null)
          setRuleScheduleModalVisible(false)
        }}
      />
    </>
  )
}

export default memo(PolicyTable)
