import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@signifyd/components'
import { WarningOutlined } from '@ant-design/icons'
import styles from './ErrorComponent.less'

const ErrorComponent: FC = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.container} data-test-id="policyReportError">
      <WarningOutlined />
      <Text className={styles.text} block size="sm">
        {t('ruleSummaryPage.policyReports.error')}
      </Text>
    </div>
  )
}

export default ErrorComponent
