import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { PredictionListLastEvent, PREDICTION_LIST_TYPE } from '@signifyd/http'
import { Text } from '@signifyd/components'
import FormatDate from 'core/components/FormatDate'
import useUserTeams from 'core/hooks/useUserTeams/useUserTeams'

export interface Props {
  updatedAt: string
  updatedByName: string
  listType: PREDICTION_LIST_TYPE
  lastEvent: PredictionListLastEvent
}

const ListLastEvent: FC<Props> = ({
  updatedAt,
  listType,
  updatedByName,
  lastEvent,
}) => {
  const { t } = useTranslation()
  const { userTimeZone } = useUserTeams()

  return (
    <div data-test-id="listLastEvent">
      <div>
        <Text size="xs" type="secondary">
          <FormatDate
            format="M/D/YYYY h:mm A"
            UTCTimestamp={updatedAt}
            timeZone={userTimeZone}
            showTimeZoneAbbr
          />
        </Text>{' '}
        <Text size="xs">{updatedByName}</Text>
      </div>
      <div>
        <Text size="sm" block>
          {t(`listDetailsPage.sidePanel.lastEventText.${lastEvent.eventType}`, {
            type: t(`listCommon.listTypeLowerCase.${listType}`),
            count: lastEvent.affectedEntryCount || 0,
          })}
        </Text>
      </div>
    </div>
  )
}

export default ListLastEvent
