import { PREDICTION_LIST_ENTRY_SORT_BY } from '@signifyd/http'
import { DEFAULT_STATUS } from 'core/http'
import { PredictionListEntriesState } from './predictionListEntries.types'

export const GET_LIST_ENTRIES_LIMIT = 50

export const DEFAULT_CREATE_LIST_ENTRIES_HTTP_STATUS = {
  ...DEFAULT_STATUS,
  hasDuplicateEntries: false,
}

export const DEFAULT_GET_LIST_ENTRIES_FILTERS = {
  page: 0,
  pageSize: GET_LIST_ENTRIES_LIMIT,
  searchTerm: null,
  ascending: false,
  orderBy: PREDICTION_LIST_ENTRY_SORT_BY.SORT_CREATED_AT,
  isExpired: false,
}

export const DEFAULT_HTTP_STATUSES = {
  listPredictionListEntries: { ...DEFAULT_STATUS },
  createPredictionListEntriesWithJSON: {
    ...DEFAULT_CREATE_LIST_ENTRIES_HTTP_STATUS,
  },
  createPredictionListEntriesWithCSV: {
    ...DEFAULT_CREATE_LIST_ENTRIES_HTTP_STATUS,
  },
  replacePredictionListEntriesWithJSON: { ...DEFAULT_STATUS },
  replacePredictionListEntriesWithCSV: { ...DEFAULT_STATUS },
  deletePredictionListEntries: { ...DEFAULT_STATUS },
  downloadPredictionListEntries: { ...DEFAULT_STATUS },
}

export const DEFAULT_STATE = {
  predictionListEntries: null,
  totalPredictionListEntries: null,
  selectedEntryIds: [],
  filters: { ...DEFAULT_GET_LIST_ENTRIES_FILTERS },
  HTTPStatuses: { ...DEFAULT_HTTP_STATUSES },
}

const state: PredictionListEntriesState = { ...DEFAULT_STATE }

export default state
