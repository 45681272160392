import { useState, FC } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import CreateListModal from 'pages/ListPage/containers/CreateListModal'
import useCreatePredictionList from 'core/hooks/useCreatePredictionList'
import { HTTPStatus } from 'core/http'

interface Props {
  teamId: string
}

const CreateListButtonWithModal: FC<Props> = ({ teamId }) => {
  const { t } = useTranslation()
  const [modalVisible, setModalVisible] = useState(false)

  const {
    mutateAsync: createPredictionList,
    isLoading,
    isSuccess,
    error,
  } = useCreatePredictionList()

  const createPredictionListHTTPStatus: HTTPStatus = {
    pending: isLoading,
    error: error?.message,
    success: isSuccess,
  }

  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => setModalVisible(true)}
        data-test-id="showCreateListModalButton"
      >
        {t('listCommon.createListBtn')}
      </Button>
      <CreateListModal
        visible={modalVisible}
        httpStatus={createPredictionListHTTPStatus}
        onCancel={() => setModalVisible(false)}
        onOk={async (listDetails) => {
          await createPredictionList({ teamId: +teamId, ...listDetails })
        }}
      />
    </>
  )
}

export default CreateListButtonWithModal
