import { FC, ReactNode } from 'react'
import { Col, Row } from 'antd'
import { Space, IconProps } from '@signifyd/components'
import styles from './GridSelect.less'

interface RadioButtonItem {
  icon: FC<IconProps>
  key: string
  label: ReactNode
  testId?: string
}

export interface Props {
  centered?: boolean
  items: Array<RadioButtonItem>
  span: 8 | 12
  value?: string
  onChange: (value: any) => void
}

const GRID_GUTTER: [number, number] = [16, 16]

const GridSelect: FC<Props> = ({ centered, items, value, span, onChange }) => {
  return (
    <div
      className={
        centered ? `${styles.wrapper} ${styles.centered}` : styles.wrapper
      }
    >
      <Row gutter={GRID_GUTTER} className={styles[`rowSpan${span}`]}>
        {items.map(({ icon: Icon, key, label, testId }) => (
          <Col key={key} span={span} className={styles.col}>
            <div
              data-test-id={testId}
              className={
                key === value ? `${styles.item} ${styles.active}` : styles.item
              }
              onClick={() => onChange(key)}
            >
              <Icon className={styles.icon} />
              <Space size="xs" />
              <div>{label}</div>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default GridSelect
