export default {
  appName: 'Lists',
  createListBtn: 'Create list',
  listTypeStartCase: {
    EMAIL: 'Email',
    CARD_BIN: 'Credit card BIN',
    IP_ADDRESS: 'IP address',
    DEVICE_ID: 'Device ID',
    ITEM_ID: 'Item ID',
    DISCOUNT_CODE: 'Discount code',
    PHONE_NUMBER: 'Phone number',
    ADDRESS: 'Address',
    EMAIL_DOMAIN: 'Email domain',
    MEMBERSHIP_ID: 'Membership ID',
    ACCOUNT_NUMBER: 'Account number',
    TAX_ID: 'Tax ID',
  },
  listTypeLowerCase: {
    EMAIL: 'email',
    CARD_BIN: 'credit card BIN',
    IP_ADDRESS: 'IP address',
    DEVICE_ID: 'device ID',
    ITEM_ID: 'item ID',
    DISCOUNT_CODE: 'discount code',
    PHONE_NUMBER: 'phone number',
    ADDRESS: 'address',
    EMAIL_DOMAIN: 'email domain',
    MEMBERSHIP_ID: 'membership ID',
    ACCOUNT_NUMBER: 'account number',
    TAX_ID: 'tax ID',
  },
  listTypeUpperCase: {
    EMAIL: 'EMAIL',
    CARD_BIN: 'CREDIT CARD BIN',
    IP_ADDRESS: 'IP ADDRESS',
    DEVICE_ID: 'DEVICE ID',
    ITEM_ID: 'ITEM_ID',
    DISCOUNT_CODE: 'DISCOUNT CODE',
    PHONE_NUMBER: 'PHONE NUMBER',
    ADDRESS: 'ADDRESS',
    EMAIL_DOMAIN: 'EMAIL DOMAIN',
    MEMBERSHIP_ID: 'MEMBERSHIP ID',
    ACCOUNT_NUMBER: 'ACCOUNT NUMBER',
    TAX_ID: 'TAX ID',
  },
  listTypeTag: {
    EMAIL: 'Email',
    CARD_BIN: 'Credit card BIN',
    IP_ADDRESS: 'IP address',
    DEVICE_ID: 'Device ID',
    ITEM_ID: 'Item ID',
    DISCOUNT_CODE: 'Discount code',
    PHONE_NUMBER: 'Phone number',
    ADDRESS: 'Address',
    EMAIL_DOMAIN: 'Email domain',
    MEMBERSHIP_ID: 'Membership ID',
    ACCOUNT_NUMBER: 'Account number',
    TAX_ID: 'Tax ID',
  },
  listDetailsForm: {
    nameLabel: 'Name',
    namePlaceholder: 'Hint: Short names are best, max {{max}} characters',
    nameUpdateDisabled:
      'This list is being used in one or more policies, thus the name cannot be updated at this time',
    descriptionLabel: 'Description (optional)',
    descriptionPlaceholder: 'Hint: Describe purpose of list',
  },
  createListModal: {
    currentStep: 'STEP {{currentStep}} of 2',
    title: 'Choose list type',
    stepOneSubtitle:
      'The type of list you choose will be a variable used in a policy configuration.',
    stepOneDescription:
      "For example if you want to write a policy to accept orders from known good email addresses, you'll create an <i>Email</i> list.",
    stepOneOk: 'Continue',
    stepOneCancel: 'Cancel',
    stepTwoTitle: 'Name your {{listType}} list',
    stepTwoSubtitle:
      'Your teammates will see this name and description so write something that everyone will easily understand.',
    stepTwoTitleListType: {
      EMAIL: 'email',
      CARD_BIN: 'credit card BIN',
      IP_ADDRESS: 'IP address',
      DEVICE_ID: 'device ID',
      ITEM_ID: 'item ID',
      DISCOUNT_CODE: 'discount code',
      PHONE_NUMBER: 'phone number',
      ADDRESS: 'address',
      EMAIL_DOMAIN: 'email domain',
      MEMBERSHIP_ID: 'membership ID',
      ACCOUNT_NUMBER: 'account number',
      TAX_ID: 'tax ID',
    },
    stepTwoOk: 'Create list',
    stepTwoCancel: 'Previous',
  },
  createListFromPolicyModal: {
    title: 'Create list',
    stepOneTitle: "You're creating {{listType}} list",
    stepOneTitleListType: {
      EMAIL: 'an email',
      CARD_BIN: 'a credit card BIN',
      IP_ADDRESS: 'an IP address',
      DEVICE_ID: 'a device ID',
      ITEM_ID: 'an item ID',
      DISCOUNT_CODE: 'a discount code',
      PHONE_NUMBER: 'a phone number',
      ADDRESS: 'a address',
      EMAIL_DOMAIN: 'an email domain',
      MEMBERSHIP_ID: 'an membership ID',
      ACCOUNT_NUMBER: 'a account number',
      TAX_ID: 'a tax ID',
    },
    stepOneOk: 'Finish',
    stepOneCancel: 'Cancel',
    stepTwoTitle:
      'Success! List created. Add items to {{listType}} list now. How would you like to add?',
    stepTwoTitleListType: {
      EMAIL: 'email',
      CARD_BIN: 'credit card BIN',
      IP_ADDRESS: 'IP address',
      DEVICE_ID: 'device ID',
      ITEM_ID: 'item ID',
      DISCOUNT_CODE: 'discount code',
      PHONE_NUMBER: 'phone number',
      ADDRESS: 'address',
      EMAIL_DOMAIN: 'email domain',
      MEMBERSHIP_ID: 'membership ID',
      ACCOUNT_NUMBER: 'account number',
      TAX_ID: 'tax ID',
    },
    stepTwoOk: 'Apply',
    stepTwoCancel: 'Close',
  },
  editListDetailsModal: {
    title: 'Edit details',
    ok: 'Apply',
    cancel: 'Cancel',
  },
  deleteListModal: {
    title: 'Delete list',
    info: 'This action is permanent and cannot be undone.',
    description:
      'Once you delete a list it will no longer be available to use in a policy.',
    note: 'To continue, select <bold>Delete</bold>.',
    ok: 'Delete',
    cancel: 'Cancel',
  },
  fallbackErrorMessage: {
    getLists:
      'Apologies — our system encountered an error retrieving lists. Try again.',
    getCurrentList:
      'Apologies — our system encountered an error retrieving list. Try again.',
    createList:
      'Apologies — our system encountered an error creating new list. Try again.',
    updateList:
      'Apologies — our system encountered an error updating list. Try again.',
    deleteList:
      'Apologies — our system encountered an error deleting list. Try again.',
    getListEntries:
      'Apologies — our system encountered an error retrieving list items. Try again.',
    createListEntries:
      'Apologies — our system encountered an error creating list items. Try again.',
    invalidListEntries: 'Invalid values',
    replaceListEntries:
      'Apologies — our system encountered an error replacing list items. Try again.',
    deleteListEntries:
      'Apologies — our system encountered an error deleting list items. Try again.',
    downloadListEntries:
      'Apologies — our system encountered an error downloading list items. Try again.',
  },
  successMessage: {
    deleteList: 'Success! List deleted.',
    createListEntries_one: 'Success! {{count}} item added to list.',
    createListEntries_other: 'Success! {{count}} items added to list.',
    noEntriesAdded: 'No items added because all are duplicates',
    replaceListEntries: 'Success! Items in list replaced with new.',
    deleteListEntries_one: 'Success! {{count}} item deleted from list.',
    deleteListEntries_other: 'Success! {{count}} items deleted from list.',
  },
  emailDomain: {
    toggleTooltip:
      '<p>Domain cannot include comma (,) or @ sign</p><p>Must end with an extension (e.g. .com, .net, etc.)</p>',
  },
}
