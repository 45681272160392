import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  Space,
  Text,
  CreditCardAltIcon,
  DeviceIdIcon,
  EmailIcon,
  IpAddressIcon,
  ItemIdIcon,
  DiscountCodeIcon,
  PhoneNumberIcon,
  AddressIcon,
  EmailDomainIcon,
  MembershipIcon,
  AccountNumberIcon,
  TaxIdIcon,
} from '@signifyd/components'
import { PREDICTION_LIST_TYPE } from '@signifyd/http'
import GridSelect from 'core/components/GridSelect'

interface Props {
  listType?: PREDICTION_LIST_TYPE
  onChange: (listType: PREDICTION_LIST_TYPE) => void
}

const ListTypeModalContent: FC<Props> = ({ listType, onChange }) => {
  const { t } = useTranslation()

  return (
    <>
      <Text size="sm" block weight="semibold">
        {t('listCommon.createListModal.stepOneSubtitle')}
      </Text>
      <Space size="sm" />
      <Text size="sm" type="secondary">
        <Trans i18nKey="listCommon.createListModal.stepOneDescription" />
      </Text>
      <Space size="md" />
      <GridSelect
        items={[
          {
            key: PREDICTION_LIST_TYPE.EMAIL,
            label: t('listCommon.listTypeStartCase.EMAIL'),
            icon: EmailIcon,
            testId: 'emailListGridTile',
          },
          {
            key: PREDICTION_LIST_TYPE.ADDRESS,
            label: t('listCommon.listTypeStartCase.ADDRESS'),
            icon: AddressIcon,
            testId: 'addressListGridTile',
          },
          {
            key: PREDICTION_LIST_TYPE.PHONE_NUMBER,
            label: t('listCommon.listTypeStartCase.PHONE_NUMBER'),
            icon: PhoneNumberIcon,
            testId: 'phoneNumberListGridTile',
          },
          {
            key: PREDICTION_LIST_TYPE.DEVICE_ID,
            label: t('listCommon.listTypeStartCase.DEVICE_ID'),
            icon: DeviceIdIcon,
            testId: 'deviceIdListGridTile',
          },
          {
            key: PREDICTION_LIST_TYPE.IP_ADDRESS,
            label: t('listCommon.listTypeStartCase.IP_ADDRESS'),
            icon: IpAddressIcon,
            testId: 'ipAddressListGridTile',
          },
          {
            key: PREDICTION_LIST_TYPE.CARD_BIN,
            label: t('listCommon.listTypeStartCase.CARD_BIN'),
            icon: CreditCardAltIcon,
            testId: 'cardBinListGridTile',
          },
          {
            key: PREDICTION_LIST_TYPE.DISCOUNT_CODE,
            label: t('listCommon.listTypeStartCase.DISCOUNT_CODE'),
            icon: DiscountCodeIcon,
            testId: 'discountCodeListGridTile',
          },
          {
            key: PREDICTION_LIST_TYPE.ITEM_ID,
            label: t('listCommon.listTypeStartCase.ITEM_ID'),
            icon: ItemIdIcon,
            testId: 'itemIdListGridTile',
          },
          {
            key: PREDICTION_LIST_TYPE.EMAIL_DOMAIN,
            label: t('listCommon.listTypeStartCase.EMAIL_DOMAIN'),
            icon: EmailDomainIcon,
            testId: 'emailDomainListGridTile',
          },
          {
            key: PREDICTION_LIST_TYPE.MEMBERSHIP_ID,
            label: t('listCommon.listTypeStartCase.MEMBERSHIP_ID'),
            icon: MembershipIcon,
            testId: 'membershipIdListGridTile',
          },
          {
            key: PREDICTION_LIST_TYPE.ACCOUNT_NUMBER,
            label: t('listCommon.listTypeStartCase.ACCOUNT_NUMBER'),
            icon: AccountNumberIcon,
            testId: 'accountNumberListGridTile',
          },
          {
            key: PREDICTION_LIST_TYPE.TAX_ID,
            label: t('listCommon.listTypeStartCase.TAX_ID'),
            icon: TaxIdIcon,
            testId: 'taxIdListGridTile',
          },
        ]}
        span={8}
        value={listType}
        onChange={onChange}
      />
    </>
  )
}

export default ListTypeModalContent
