import { isEmpty, toNumber, isInteger } from 'lodash'
import { PREDICTION_LIST_ENTRY_SORT_BY } from '@signifyd/http'
import { GetListEntriesFilters } from 'stores/predictionList'
import { ListDetailsPageQueryParams } from './useListDetailsPageQueryParams'

function isValidOrderBy(
  value?: string | null
): value is PREDICTION_LIST_ENTRY_SORT_BY | undefined {
  return (
    !value ||
    value === PREDICTION_LIST_ENTRY_SORT_BY.SORT_CREATED_AT ||
    value === PREDICTION_LIST_ENTRY_SORT_BY.SORT_CREATED_BY_NAME
  )
}

export const getStoreFiltersFromSearchParams = (
  params: Partial<ListDetailsPageQueryParams>
): GetListEntriesFilters | null => {
  if (isEmpty(params)) {
    return null
  }

  const { searchTerm, ascending, orderBy } = params

  if (params.page && !isInteger(params.page)) {
    return null
  }

  const pageSize = toNumber(params.pageSize)

  if (params.pageSize && !isInteger(pageSize)) {
    return null
  }

  if (!isValidOrderBy(orderBy)) {
    return null
  }

  const filters: GetListEntriesFilters = {
    page: params.page,
    pageSize,
    searchTerm: searchTerm || null,
    ascending: null,
    orderBy: orderBy || null,
  }

  if (ascending === 'true' || ascending === 'false') {
    filters.ascending = ascending === 'true'
  }

  return filters
}
