import { FC, useRef, ReactNode } from 'react'
import { Table } from 'antd'
import { Space } from '@signifyd/components'
import { PredictionList } from '@signifyd/http'
import { useTranslation } from 'react-i18next'
import { GET_LIST_ENTRIES_LIMIT } from 'stores/predictionList'
import { useStoreActions, useStoreState } from 'stores'
import styles from './ListEntriesTable.less'
import useListEntriesColumnConfig from './useListEntriesColumnConfig'
import useScrollingTableBody from './useScrollingTableBody'
import ListEntriesTableHeader from './ListEntriesTableHeader'
import useSetQueryOnTableChange from './useSearchParamsOnTableChange'
import EmptyTableText from './EmptyTableText'

export interface Props {
  predictionList: PredictionList
}

const ListEntriesTable: FC<Props> = ({ predictionList }) => {
  const { t } = useTranslation()
  const tableElm = useRef<HTMLDivElement>(null)
  const tableScrollY = useScrollingTableBody(tableElm)

  const listPredictionListEntriesHTTPStatus = useStoreState(
    (state) =>
      state.predictionListEntriesModel.HTTPStatuses.listPredictionListEntries
  )
  const predictionListEntries = useStoreState(
    (state) => state.predictionListEntriesModel.predictionListEntries
  )
  const totalPredictionListEntries = useStoreState(
    (state) => state.predictionListEntriesModel.totalPredictionListEntries
  )
  const selectedEntryIds = useStoreState(
    (state) => state.predictionListEntriesModel.selectedEntryIds
  )
  const filters = useStoreState(
    (state) => state.predictionListEntriesModel.filters
  )
  const hasAnyPredictionListEntries = useStoreState(
    (state) => state.predictionListEntriesModel.hasAnyPredictionListEntries
  )

  const { deletePredictionListEntries, setSelectedEntryIds } = useStoreActions(
    (actions) => actions.predictionListEntriesModel
  )

  const onDeleteSelectedEntries = (): void => {
    deletePredictionListEntries({
      predictionListId: predictionList.id,
      ids: selectedEntryIds,
    })
  }

  const showTotalNumber = (
    total: number,
    [start, end]: [number, number]
  ): ReactNode => (
    <span>
      {t('listDetailsPage.listEntriesTable.pagination.totalNumber', {
        start,
        end,
        total,
      })}
    </span>
  )

  const columnConfig = useListEntriesColumnConfig(predictionList)

  const loading = listPredictionListEntriesHTTPStatus.pending

  const onTableChange = useSetQueryOnTableChange()

  return (
    <>
      <Space size="md" />
      <ListEntriesTableHeader
        predictionList={predictionList}
        filters={filters}
        onDeleteSelectedEntries={onDeleteSelectedEntries}
      />
      <div ref={tableElm} />
      <Table
        data-test-id="list-entries-table"
        rowKey="id"
        size="middle"
        loading={loading}
        locale={{
          emptyText: <EmptyTableText loading={loading} />,
        }}
        pagination={{
          position: ['bottomCenter'],
          total: totalPredictionListEntries ?? undefined,
          showTotal: showTotalNumber,
          current: filters.page ? filters.page + 1 : 1,
          pageSize: filters.pageSize || GET_LIST_ENTRIES_LIMIT,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100'],
          className: styles.pagination,
        }}
        rowSelection={{
          selectedRowKeys: selectedEntryIds,
          onChange: (ids) => {
            setSelectedEntryIds(ids as Array<number>)
          },
        }}
        scroll={{ y: tableScrollY }}
        onChange={onTableChange}
        columns={columnConfig}
        dataSource={predictionListEntries || undefined}
        className={!hasAnyPredictionListEntries ? styles.emptyTable : undefined}
      />
    </>
  )
}

export default ListEntriesTable
