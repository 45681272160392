import { Button, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { NavigateFunction } from 'react-router-dom'
import { RuleResponse, PolicyMatchId } from '@signifyd/http'
import useUserTeams from 'core/hooks/useUserTeams'
import { useStoreActions } from 'stores'
import styles from './SubmitButton.less'

interface SubmitButtonProps {
  areInputsDirty: boolean
  didValidationsFail: boolean
  loading: boolean
  areValidationsPending: boolean
  areResultsInvalid: boolean
  isEditing: boolean
  policy: RuleResponse
  navigate: NavigateFunction
  matchingPolicyMatchIds: Array<PolicyMatchId>
  nonMatchingPolicyMatchIds: Array<PolicyMatchId>
}

export const SubmitButton: React.FC<SubmitButtonProps> = ({
  loading,
  areInputsDirty,
  didValidationsFail,
  areResultsInvalid,
  areValidationsPending,
  isEditing,
  navigate,
  matchingPolicyMatchIds,
  nonMatchingPolicyMatchIds,
  policy: { rankInActiveRuleSet, checkpoint },
}) => {
  const { t } = useTranslation()
  const { setHomepageToDashboard } = useUserTeams()

  const { handleFinishPolicyValidation } = useStoreActions(
    (actions) => actions.ruleModel
  )

  const getTooltipTitle = (
    areInputsDirty: boolean,
    didValidationsFail: boolean
  ): string => {
    if (didValidationsFail) {
      return t('ruleValidationPage.tooltipTitles.validationFailure')
    }

    if (areInputsDirty) {
      return t('ruleValidationPage.tooltipTitles.dirtyInputs')
    }

    return ''
  }

  const getButtonText = (): string => {
    if (!isEditing || !rankInActiveRuleSet) {
      return t('ruleValidationPage.finish')
    }

    return t('ruleValidationPage.next')
  }

  return (
    <Tooltip title={getTooltipTitle(areInputsDirty, didValidationsFail)}>
      <Button
        data-test-id="finishButton"
        type="primary"
        className={styles.nextBtn}
        loading={loading}
        disabled={
          areInputsDirty ||
          didValidationsFail ||
          areValidationsPending ||
          areResultsInvalid
        }
        onClick={() => {
          handleFinishPolicyValidation({
            navigate,
            policyValidationPayload: {
              isEditing,
              checkpoint,
              matchingPolicyMatchIds,
              nonMatchingPolicyMatchIds,
            },
          })
            .then(() => setHomepageToDashboard())
            .catch((err: Error) => console.log(err))
        }}
      >
        {getButtonText()}
      </Button>
    </Tooltip>
  )
}
