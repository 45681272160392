import { FC, useState } from 'react'
import { DragDropContext, OnDragEndResponder } from 'react-beautiful-dnd'
import { useTranslation, Trans } from 'react-i18next'
import { useQueryParams } from 'use-query-params'
import { T4, Text, Space } from '@signifyd/components'
import { RuleResponse, RuleSet } from '@signifyd/http'
import { UseMutateFunction } from '@tanstack/react-query'
import { PublishPageQueryParams } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.config'
import {
  DROPPABLE_ID,
  SPLIT_SCREEN_STEP,
} from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.types'
import {
  changeRuleSchedule,
  moveToOtherRules,
  moveToPublishedRules,
  reorderRules,
} from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.utils'
import DroppableRuleList from './RuleSetDnDDroppableRuleList'
import styles from './RuleSetDnD.less'

interface Props {
  ruleSet?: RuleSet
  publishedRules: Array<RuleResponse>
  otherRules: Array<RuleResponse>
  mutate: UseMutateFunction<
    RuleSet,
    unknown,
    {
      ruleSet: RuleSet
      publishedRules: Array<RuleResponse>
      otherRules: Array<RuleResponse>
    },
    unknown
  >
}

export const UPDATE_RULE_SET_DEBOUNCE_RATE_MS = 1000

const RuleSetDnD: FC<Props> = ({
  ruleSet,
  publishedRules,
  otherRules,
  mutate,
}) => {
  const { t } = useTranslation()
  const [queryParams, setQueryParams] = useQueryParams(PublishPageQueryParams)
  const { selectedRuleId, step } = queryParams

  const [orderedPublishedRules, setOrderedPublishedRules] =
    useState(publishedRules)
  const [orderedOtherRules, setOrderedOtherRules] = useState(otherRules)

  const debouncedUpdateRuleSet = (
    updatedPublishedRules: Array<RuleResponse>,
    updatedOtherRules: Array<RuleResponse>
  ): void => {
    if (!ruleSet) {
      return
    }

    mutate({
      ruleSet,
      publishedRules: updatedPublishedRules,
      otherRules: updatedOtherRules,
    })
  }

  if (!orderedPublishedRules || !orderedOtherRules) {
    return null
  }

  const clearSelectedFilters = (): void => {
    setQueryParams({ selectedRuleId: undefined, selectedRuleAction: undefined })
  }

  const handleDragEnd: OnDragEndResponder = ({ source, destination }): void => {
    if (!destination) {
      return
    }

    const isPublished = destination.droppableId === DROPPABLE_ID.PUBLISHED

    if (source.droppableId === destination.droppableId) {
      const result = reorderRules(
        isPublished ? orderedPublishedRules : orderedOtherRules,
        source.index,
        destination.index
      )

      clearSelectedFilters()

      if (isPublished) {
        setOrderedPublishedRules(result)
      } else {
        setOrderedOtherRules(result)
      }

      debouncedUpdateRuleSet(
        isPublished ? result : orderedPublishedRules,
        isPublished ? orderedOtherRules : result
      )
    } else {
      const moveToDestRules = isPublished
        ? moveToPublishedRules
        : moveToOtherRules

      const result = moveToDestRules(
        {
          publishedRules: orderedPublishedRules,
          otherRules: orderedOtherRules,
        },
        source.index,
        destination.index
      )

      clearSelectedFilters()

      setOrderedPublishedRules(result.publishedRules)
      setOrderedOtherRules(result.otherRules)

      debouncedUpdateRuleSet(result.publishedRules, result.otherRules)
    }
  }

  const handleMoveToPublished = (srcIndex: number): void => {
    const result = moveToPublishedRules(
      { publishedRules: orderedPublishedRules, otherRules: orderedOtherRules },
      srcIndex,
      orderedPublishedRules.length
    )

    clearSelectedFilters()

    setOrderedPublishedRules(result.publishedRules)
    setOrderedOtherRules(result.otherRules)

    debouncedUpdateRuleSet(result.publishedRules, result.otherRules)
  }

  const handleMoveToOthers = (srcIndex: number): void => {
    const result = moveToOtherRules(
      { publishedRules: orderedPublishedRules, otherRules: orderedOtherRules },
      srcIndex,
      0
    )

    clearSelectedFilters()

    setOrderedPublishedRules(result.publishedRules)
    setOrderedOtherRules(result.otherRules)

    debouncedUpdateRuleSet(result.publishedRules, result.otherRules)
  }

  const handleChangeRuleSchedule = ({
    index,
    scheduleStart,
    scheduleEnd,
  }: {
    index: number
    scheduleStart: string | null
    scheduleEnd: string | null
  }): void => {
    const result = changeRuleSchedule(
      orderedPublishedRules,
      index,
      scheduleStart,
      scheduleEnd
    )

    setOrderedPublishedRules(result)

    debouncedUpdateRuleSet(result, orderedOtherRules)
  }

  const helpHint =
    step === SPLIT_SCREEN_STEP.STEP_ONE ? (
      <Text type="secondary" block className={styles.helpHint}>
        <Trans
          i18nKey="publishWithSimulatorPage.ruleSetDnD.helpHint"
          components={{ bold: <Text weight="semibold" type="secondary" /> }}
        />
      </Text>
    ) : (
      <Space size="md" />
    )

  return (
    <>
      <Space size="lg" />
      {helpHint}
      <Space size="lg" />
      <DragDropContext onDragEnd={handleDragEnd}>
        <T4>
          {t('publishWithSimulatorPage.ruleSetDnD.publishedTitle')} (
          {orderedPublishedRules.length})
        </T4>
        <DroppableRuleList
          placeholder={t(
            'publishWithSimulatorPage.ruleSetDnD.publishedPlaceholder'
          )}
          selectedRuleId={selectedRuleId || null}
          droppableId={DROPPABLE_ID.PUBLISHED}
          rules={orderedPublishedRules}
          onMoveRuleToAnotherList={handleMoveToOthers}
          onChangeRuleSchedule={handleChangeRuleSchedule}
        />
        <Space size="lg" />
        <T4>
          {t('publishWithSimulatorPage.ruleSetDnD.othersTitle')} (
          {orderedOtherRules.length})
        </T4>
        <DroppableRuleList
          placeholder={t(
            'publishWithSimulatorPage.ruleSetDnD.othersPlaceholder'
          )}
          selectedRuleId={selectedRuleId || null}
          droppableId={DROPPABLE_ID.OTHERS}
          rules={orderedOtherRules}
          onMoveRuleToAnotherList={handleMoveToPublished}
        />
      </DragDropContext>
      <Space size="lg" />
    </>
  )
}

export default RuleSetDnD
