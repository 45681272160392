import { FC, ReactNode } from 'react'

import styles from './FlippedStatistic.less'

interface Props {
  title: string
  value: number
  suffix?: ReactNode
  'data-test-id': string
}

const FlippedStatistic: FC<Props> = ({
  title,
  value,
  suffix,
  'data-test-id': dataTestId,
}) => {
  return (
    <div className={styles.container} data-test-id={`${dataTestId}Container`}>
      <span className={styles.content} data-test-id={`${dataTestId}Value`}>
        {value}
      </span>
      {suffix && (
        <span className={styles.suffix} data-test-id={`${dataTestId}Suffix`}>
          {suffix}
        </span>
      )}
      <span className={styles.title} data-test-id={`${dataTestId}Title`}>
        {title}
      </span>
    </div>
  )
}

export default FlippedStatistic
