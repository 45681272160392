import axios, { AxiosError } from 'axios'
import { isString } from 'lodash'
import { message } from 'antd'

export interface HTTPStatus {
  pending: boolean
  success: boolean
  error: string | undefined
}

export const DEFAULT_STATUS = {
  pending: false,
  success: false,
  error: undefined,
}

export const START_STATUS = {
  pending: true,
  success: false,
  error: undefined,
}

export const SUCCESS_STATUS = {
  pending: false,
  success: true,
  error: undefined,
}

/**
 * Tries to parse a valid error message from the given error; else returns the fallback message.
 *
 * Note: For 500 errors, we have elected to always return the fallback so it will be used in that case
 */
export function getHTTPErrorMessage(error: unknown, fallback: string): string {
  if (!axios.isAxiosError(error)) {
    return fallback
  }

  if (isString(error)) {
    return error
  }

  const INTERNAL_SERVER_ERROR_STATUS = 500
  if (error?.response?.status === INTERNAL_SERVER_ERROR_STATUS) {
    return fallback
  }

  return (error?.response?.data as any)?.messages?.[0] ?? fallback
}

export function getHTTPErrorStatus(
  error: unknown,
  fallback: string
): HTTPStatus {
  return {
    pending: false,
    success: false,
    error: getHTTPErrorMessage(error, fallback),
  }
}

/**
 * Broadcast an antd error message for caught errors
 */
export const messageOnError = (fallbackErrorMessage: string) => {
  return (error: AxiosError): void => {
    message.error({ content: getHTTPErrorMessage(error, fallbackErrorMessage) })
  }
}

/**
 * Return a rejected promise (with the parsed error message) for caught errors
 */
export const rejectOnError = (fallbackErrorMessage: string) => {
  return (error: AxiosError): Promise<never> => {
    return Promise.reject(getHTTPErrorMessage(error, fallbackErrorMessage))
  }
}

export * from './common'
