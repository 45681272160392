import { FC } from 'react'
import { SigTag, Text } from '@signifyd/components'
import { useTranslation } from 'react-i18next'
import { colorIrisLight80, colorLapisLight80 } from '@signifyd/colors'
import { PREDICTION_LIST_TYPE } from '@signifyd/http'
import { FileDoneOutlined } from '@ant-design/icons'
import cx from 'classnames'
import styles from './PredictionListTypeTag.less'

interface Props {
  type: PREDICTION_LIST_TYPE
  className?: string
  bordered?: boolean
}

interface StyleType {
  color: ColorHex
  typeClass: string
  showIcon: boolean
}

type ColorHex = `#${string}`

const RESELLER_ABUSE_STYLES = {
  color: colorIrisLight80,
  typeClass: '',
  showIcon: true,
}

const DEFAULT_STYLES = {
  color: colorLapisLight80,
  typeClass: styles.blueBorder,
  showIcon: false,
}

export const typeToStyles = (type: PREDICTION_LIST_TYPE): StyleType =>
  ['PROMO_ABUSE', 'RESELLER_ABUSE'].includes(type)
    ? RESELLER_ABUSE_STYLES
    : DEFAULT_STYLES

const PredictionListTypeTag: FC<Props> = ({ className, type, bordered }) => {
  const { t } = useTranslation()
  const { typeClass, showIcon, color } = typeToStyles(type)

  if (!(type in PREDICTION_LIST_TYPE)) {
    return null
  }

  return (
    <SigTag
      color={color}
      type="primary"
      className={cx([styles.predictionTag, className, bordered && typeClass])}
      bordered
    >
      {showIcon && <FileDoneOutlined />}
      <Text size="sm">{t(`listCommon.listTypeTag.${type}`)}</Text>
    </SigTag>
  )
}

export default PredictionListTypeTag
