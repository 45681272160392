import { FC, useState, useEffect } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { PredictionList } from '@signifyd/http'
import CreateListEntriesModal from 'pages/ListDetailsPage/containers/CreateListEntriesModal'
import { useStoreState } from 'stores'
import { useListDetailsPageQueryParams } from 'pages/ListDetailsPage/useListDetailsPageQueryParams'

interface Props {
  predictionList: PredictionList
}

const CreateItemsButtonWithModal: FC<Props> = ({ predictionList }) => {
  const { t } = useTranslation()
  const [searchParams] = useListDetailsPageQueryParams()
  const [listEntriesModalVisible, setListEntriesModalVisible] = useState(
    searchParams.isNewList === 'true'
  )

  const {
    createPredictionListEntriesWithJSON: createWithJSONHTTPStatus,
    createPredictionListEntriesWithCSV: createWithCSVHTTPStatus,
  } = useStoreState((state) => state.predictionListEntriesModel.HTTPStatuses)

  // Upon completion of the upload, hide the modal - note that this will not hide the modal if there are duplicates or it fails
  useEffect(() => {
    if (createWithJSONHTTPStatus.success || createWithCSVHTTPStatus.success) {
      setListEntriesModalVisible(false)
    }
  }, [createWithJSONHTTPStatus, createWithCSVHTTPStatus])

  return (
    <div data-test-id="createListEntriesButtonWithModal">
      <Button
        block
        icon={<PlusOutlined />}
        onClick={() => setListEntriesModalVisible(true)}
        data-test-id="addItemsToListButton"
      >
        {t('listDetailsPage.sidePanel.addItems')}
      </Button>
      <CreateListEntriesModal
        onClose={() => setListEntriesModalVisible(false)}
        predictionList={predictionList}
        visible={listEntriesModalVisible}
      />
    </div>
  )
}

export default CreateItemsButtonWithModal
