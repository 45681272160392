import { FC, useState, useCallback } from 'react'
import { Modal } from 'antd'
import { ModalProps } from 'antd/es/modal'
import { useTranslation } from 'react-i18next'
import {
  CreatePredictionListPayload,
  PREDICTION_LIST_TYPE,
} from '@signifyd/http'
import { Text, Space, i18nInstance } from '@signifyd/components'
import { HTTPStatus } from 'core/http'
import { SIG_MODAL_PROPS } from 'core/constants'
import ListDetailsForm from 'core/components/ListDetailsForm'
import ListTypeModalContent from './ListTypeModalContent'
import styles from './CreateListModal.less'
import StepTitle from './StepTitle'

export enum STEP {
  one = '1',
  two = '2',
}

interface GetModalPropsByStep {
  [STEP.one]: Partial<ModalProps>
  [STEP.two]: Partial<ModalProps>
}

type PartialCreateListPayload = Omit<CreatePredictionListPayload, 'teamId'>

interface Props {
  visible: boolean
  httpStatus: HTTPStatus
  onOk: (param: PartialCreateListPayload) => void
  onCancel: () => void
}

const CreateListModal: FC<Props> = ({
  visible,
  httpStatus,
  onOk,
  onCancel,
}) => {
  const { t } = useTranslation()
  const [listType, setListType] = useState<PREDICTION_LIST_TYPE>()
  const [name, setName] = useState<string>()
  const [description, setDescription] = useState<string>()
  const [step, setStep] = useState(STEP.one)

  const resetState = useCallback((): void => {
    setListType(undefined)
    setName(undefined)
    setDescription(undefined)
    setStep(STEP.one)
  }, [setListType, setName, setDescription, setStep])

  const getModalPropsByStep: GetModalPropsByStep = {
    [STEP.one]: {
      okText: t('listCommon.createListModal.stepOneOk'),
      cancelText: t('listCommon.createListModal.stepOneCancel'),
      closable: false,
      okButtonProps: { disabled: !listType },
      onCancel: () => {
        onCancel()
      },
      onOk: () => {
        setStep(STEP.two)
      },
    },
    [STEP.two]: {
      okText: t('listCommon.createListModal.stepTwoOk'),
      cancelText: t('listCommon.createListModal.stepTwoCancel'),
      closable: true,
      okButtonProps: {
        disabled: !name?.trim(),
        loading: httpStatus.pending,
      },
      onCancel: (e) => {
        const triggeredByCloseIcon =
          e.currentTarget.className.indexOf('ant-modal-close') > -1
        if (triggeredByCloseIcon) {
          onCancel()
        } else {
          setStep(STEP.one)
        }
      },
      onOk: () => {
        if (listType && name) {
          const payload = description
            ? { type: listType, name, description }
            : { type: listType, name }

          onOk(payload)
        }
      },
    },
  }

  const modalProps = getModalPropsByStep[step]

  return (
    <Modal
      {...SIG_MODAL_PROPS}
      title={
        <>
          <Text
            size="xs"
            transform="uppercase"
            className={styles.stepText}
            block
          >
            {i18nInstance.t('listCommon.createListModal.currentStep', {
              currentStep: step,
            })}
          </Text>
          <StepTitle step={step} listType={listType} />
        </>
      }
      open={visible}
      afterClose={resetState}
      {...modalProps}
      className={styles.createListModal}
    >
      {step === STEP.one && (
        <ListTypeModalContent listType={listType} onChange={setListType} />
      )}
      {step === STEP.two && listType && (
        <>
          <Text size="sm" block>
            {t('listCommon.createListModal.stepTwoSubtitle')}
          </Text>
          <Space size="sm" />
          <ListDetailsForm
            disabled={httpStatus.pending}
            name={name}
            description={description}
            onChangeName={setName}
            onChangeDescription={setDescription}
            errorMsg={httpStatus.error}
            usedInPolicy={false}
          />
        </>
      )}
    </Modal>
  )
}

export default CreateListModal
