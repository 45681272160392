import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { PREDICTION_LIST_TYPE } from '@signifyd/http'
import { Text } from '@signifyd/components'
import styles from './CreateListModal.less'
import { STEP } from './CreateListModal'

interface Props {
  step: STEP
  listType?: PREDICTION_LIST_TYPE
}

const StepTitle: FC<Props> = ({ step, listType }) => {
  const { t } = useTranslation()

  const stepTitle = {
    [STEP.one]: t('listCommon.createListModal.title'),
    [STEP.two]: t('listCommon.createListModal.stepTwoTitle', {
      listType: t(
        `listCommon.createListFromPolicyModal.stepTwoTitleListType.${
          listType as PREDICTION_LIST_TYPE
        }`
      ),
    }),
  }

  return (
    <Text size="lg" className={styles.title}>
      {stepTitle[step]}
    </Text>
  )
}

export default StepTitle
