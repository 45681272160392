import { FC } from 'react'
import { DownloadOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { debounce } from 'lodash'
import { useStoreState, useStoreActions } from 'stores'

interface Props {
  disabled?: boolean
  predictionListId: number
}

const DownloadListEntriesButton: FC<Props> = ({
  disabled,
  predictionListId,
}) => {
  const { t } = useTranslation()
  const downloadPredictionListEntries = debounce(
    useStoreActions(
      (actions) =>
        actions.predictionListEntriesModel.downloadPredictionListEntries
    ),
    1000
  )
  const downloadPredictionListEntriesHTTPStatus = useStoreState(
    (state) =>
      state.predictionListEntriesModel.HTTPStatuses
        .downloadPredictionListEntries
  )

  return disabled ? (
    <Tooltip
      placement="left"
      title={t('listDetailsPage.sidePanel.noEntriesTooltip')}
    >
      <Button
        block
        icon={<DownloadOutlined />}
        data-test-id="disabledDownloadListEntriesButton"
        disabled
      >
        {t('listDetailsPage.sidePanel.downloadCSV')}
      </Button>
    </Tooltip>
  ) : (
    <Button
      block
      icon={<DownloadOutlined />}
      loading={downloadPredictionListEntriesHTTPStatus.pending}
      onClick={() => {
        downloadPredictionListEntries(predictionListId)
      }}
      data-test-id="enabledDownloadListEntriesButton"
    >
      {t('listDetailsPage.sidePanel.downloadCSV')}
    </Button>
  )
}

export default DownloadListEntriesButton
