import { useState, FC, useEffect } from 'react'
import { Button, Row, Col, Alert } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Layout, PageSpinner, Space } from '@signifyd/components'
import { PolicyMatchId } from '@signifyd/http'
import DiscardRuleCreationPopconfirm from 'core/components/DiscardRuleCreationPopconfirm'
import NewRuleSteps from 'core/components/NewRuleSteps'
import RuleSummaryCard from 'core/components/RuleSummaryCard'
import NewRulePageHeader from 'core/containers/NewRulePageHeader'
import useLoadPolicyForValidation from 'core/hooks/useLoadAndValidatePolicy'
import { useValidatePolicies } from 'core/hooks/useValidatePolicies/useValidatePolicies'
import { getPredictionListParamsForTeam } from 'core/http'
import { useStoreState, useStoreActions } from 'stores'
import { isPredicateUsingPredictionListFeatures } from 'stores/conditionTree'
import TestTeamBanner from 'core/components/TestTeamBanner'
import PolicyHeader from 'core/containers/PolicyHeader'
import BestPractices from './components/BestPractices'
import styles from './NewPolicyValidationPage.less'
import { filterPolicyMatchIds } from './utils/filterPolicyMatchIds'
import RuleValidationCard from './components/RuleValidationCard'
import PolicyValidationInput from './components/PolicyValidationInput'
import { SubmitButton } from './components/SubmitButton/SubmitButton'

const { Content, Footer } = Layout

interface Props {
  isEditing: boolean
  onDenyEditRule: () => void
  onDiscard: () => void
}

const PolicyValidationPage: FC<Props> = ({
  isEditing,
  onDenyEditRule,
  onDiscard,
}) => {
  const { t } = useTranslation()
  const { ruleId } = useParams()
  const navigate = useNavigate()

  const {
    conditionTree,
    isPolicyValid,
    isPolicyConditionEditable,
    policy,
    predictionListMap,
    policyHTTPStatuses: { updatePolicy: updatePolicyHTTPStatus },
  } = useStoreState((state) => state.ruleModel)

  const isCurrentPolicy = useStoreState((state) =>
    state.ruleModel.isCurrentPolicy(ruleId)
  )

  const [matchingPolicyMatchIds, setMatchingPolicyMatchIds] = useState<
    Array<PolicyMatchId>
  >([])
  const [nonMatchingPolicyMatchIds, setNonMatchingPolicyMatchIds] = useState<
    Array<PolicyMatchId>
  >([])

  const [matchingInputDirty, setMatchingInputDirty] = useState(false)
  const [nonMatchingInputDirty, setNonMatchingInputDirty] = useState(false)

  const {
    data: {
      didValidationFail: didMatchingValidationFail,
      results: matchingPolicies,
    },
    isInitialLoading: matchingPoliciesInitialLoading,
  } = useValidatePolicies(
    matchingPolicyMatchIds,
    true,
    isEditing,
    policy?.ruleId,
    policy?.teamId
  )

  const {
    data: {
      didValidationFail: didNonMatchingValidationFail,
      results: nonMatchingPolicies,
    },
    isInitialLoading: nonMatchingPoliciesInitialLoading,
  } = useValidatePolicies(
    nonMatchingPolicyMatchIds,
    false,
    isEditing,
    policy?.ruleId,
    policy?.teamId
  )

  const { listPredictionLists } = useStoreActions(
    (actions) => actions.ruleModel
  )

  useLoadPolicyForValidation(ruleId, false, isEditing)

  useEffect(() => {
    if (!!policy && !isPolicyConditionEditable && !isEditing) {
      onDenyEditRule()
    }
  }, [policy, isEditing, isPolicyConditionEditable, onDenyEditRule])

  useEffect(() => {
    if (policy) {
      const {
        draftMatchingPolicyMatchIds = [],
        draftNonMatchingPolicyMatchIds = [],
      } = policy

      setMatchingPolicyMatchIds(draftMatchingPolicyMatchIds)
      setNonMatchingPolicyMatchIds(draftNonMatchingPolicyMatchIds)
    }
  }, [policy])

  useEffect(() => {
    if (
      !predictionListMap &&
      policy &&
      // only get lists if policy is using list id feature
      isPredicateUsingPredictionListFeatures(policy.predicate)
    ) {
      const params = getPredictionListParamsForTeam(policy.teamId)

      listPredictionLists(params)
    }
  }, [predictionListMap, listPredictionLists, policy])

  const updateMatchingPolicyMatchIds = (
    newPolicyMatchIds: Array<PolicyMatchId>
  ): void => {
    const ids = filterPolicyMatchIds(
      matchingPolicies.invalidInvestigationResults,
      matchingPolicyMatchIds,
      newPolicyMatchIds
    )

    setMatchingPolicyMatchIds(ids)
  }

  const updateNonMatchingPolicyMatchIds = (
    newPolicyMatchIds: Array<PolicyMatchId>
  ): void => {
    const ids = filterPolicyMatchIds(
      nonMatchingPolicies.invalidInvestigationResults,
      nonMatchingPolicyMatchIds,
      newPolicyMatchIds
    )

    setNonMatchingPolicyMatchIds(ids)
  }

  const policyState = isEditing ? 'edit' : 'create'
  const backURL = `/policies/${policyState}/${ruleId}/conditions`

  const areInputsDirty = matchingInputDirty || nonMatchingInputDirty

  const didValidationsFail =
    didMatchingValidationFail || didNonMatchingValidationFail

  const areValidationsPending =
    matchingPoliciesInitialLoading || nonMatchingPoliciesInitialLoading

  const areResultsInvalid =
    !!matchingPolicies.invalidInvestigationResults.length ||
    !!nonMatchingPolicies.invalidInvestigationResults.length

  if (!policy || !isCurrentPolicy) {
    return (
      <>
        <PolicyHeader isEditing={isEditing} />
        <Content>
          <PageSpinner />
        </Content>
      </>
    )
  }

  return (
    <Layout>
      <TestTeamBanner
        teamId={policy.teamId}
        text={t('common.testTeamBanner.policy')}
      />
      <PolicyHeader isEditing={isEditing} />
      <Content>
        <NewRulePageHeader isEditing={isEditing} />
        <Row gutter={32} className={styles.contentRow}>
          <Col span={6}>
            <NewRuleSteps current={1} />
          </Col>
          <Col span={6}>
            {conditionTree && (
              <RuleSummaryCard
                isRuleValid={isPolicyValid}
                conditionTree={conditionTree}
                recommendedAction={policy.ruleOutcome.ruleRecommendedAction}
                linkToRuleConditions={backURL}
                editReason={policy.editReason}
              />
            )}
          </Col>

          <Col span={12}>
            <BestPractices />
            {isEditing && (
              <>
                <Space size="lg" />
                <Alert
                  message={t('ruleValidationPage.editWarning')}
                  type="warning"
                  data-test-id="editValidationWarning"
                />
              </>
            )}
            <Space size="lg" />

            <RuleValidationCard
              title={t('ruleValidationPage.matchingValidationTitle')}
              testId="matchingValidationCard"
              queryKey="matchingValidatedIds"
              loading={matchingPoliciesInitialLoading}
              validatedResults={matchingPolicies}
              clearInvalidIds={() => updateMatchingPolicyMatchIds([])}
              setPolicyMatchIds={setMatchingPolicyMatchIds}
            >
              <PolicyValidationInput
                data-test-id="matchingValidation"
                loading={matchingPoliciesInitialLoading}
                onSubmit={updateMatchingPolicyMatchIds}
                onChange={(idsToValidate, currentUserInput) => {
                  setMatchingInputDirty(
                    idsToValidate.length > 0 ||
                      currentUserInput.toString().length > 0
                  )
                }}
              />
            </RuleValidationCard>

            <Space size="lg" />

            <RuleValidationCard
              title={t('ruleValidationPage.nonMatchingValidationTitle')}
              testId="nonMatchingValidationCard"
              queryKey="nonMatchingValidatedIds"
              loading={nonMatchingPoliciesInitialLoading}
              validatedResults={nonMatchingPolicies}
              clearInvalidIds={() => updateNonMatchingPolicyMatchIds([])}
              setPolicyMatchIds={setNonMatchingPolicyMatchIds}
            >
              <PolicyValidationInput
                data-test-id="nonMatchingValidation"
                loading={nonMatchingPoliciesInitialLoading}
                onSubmit={updateNonMatchingPolicyMatchIds}
                onChange={(idsToValidate, currentUserInput) => {
                  setNonMatchingInputDirty(
                    idsToValidate.length > 0 ||
                      currentUserInput.toString().length > 0
                  )
                }}
              />
            </RuleValidationCard>
          </Col>
        </Row>
      </Content>
      <Footer>
        <Button
          type="default"
          className={styles.prevBtn}
          disabled={updatePolicyHTTPStatus.pending}
          onClick={() => {
            navigate(backURL)
          }}
        >
          {t('ruleValidationPage.previous')}
        </Button>
        <DiscardRuleCreationPopconfirm
          onConfirm={onDiscard}
          disabled={updatePolicyHTTPStatus.pending}
        />
        <SubmitButton
          areInputsDirty={areInputsDirty}
          didValidationsFail={didValidationsFail}
          areValidationsPending={areValidationsPending}
          areResultsInvalid={areResultsInvalid}
          loading={updatePolicyHTTPStatus.pending}
          isEditing={isEditing}
          policy={policy}
          navigate={navigate}
          matchingPolicyMatchIds={matchingPolicyMatchIds}
          nonMatchingPolicyMatchIds={nonMatchingPolicyMatchIds}
        />
      </Footer>
    </Layout>
  )
}

export default PolicyValidationPage
