import { FC } from 'react'
import { Row, Col } from 'antd'
import { Layout, Space } from '@signifyd/components'
import AppNavBar from 'core/containers/AppNavBar'
import ListDetailsPageSidePanel from 'pages/ListDetailsPage/containers/ListDetailsPageSidePanel'
import usePredictionListDetails from 'core/hooks/usePredictionListDetails'
import TestTeamBanner from 'core/components/TestTeamBanner'
import ListDetailsPageHeader from './ListDetailsPageHeader'
import ListDetailsPageTitle from './ListDetailsPageTitle'
import ListEntriesTableContainer from '../ListEntriesTableContainer/ListEntriesTableContainer'
import ListDetailsPageOrdersToggle from './ListDetailsPageOrdersToggle'
import styles from './ListDetailsPageContainer.less'

const { Content, NavBar } = Layout

interface Props {
  listId: number
}

// TODO FET-1829 https://signifyd.atlassian.net/browse/FET-1829
// Not sure if there is a benefit to having this container separate from ListDetailsPage (which just needs to have its hooks consolidated / fixed and then this could be pulled into that)
const ListDetailsPageContainer: FC<Props> = ({ listId }) => {
  const { data: predictionList } = usePredictionListDetails(listId)

  if (!predictionList) {
    return null
  }

  return (
    <>
      <NavBar>
        <AppNavBar teamId={predictionList.teamId} />
      </NavBar>

      <TestTeamBanner teamId={predictionList.teamId} />

      <Content>
        <ListDetailsPageHeader predictionList={predictionList} />

        <div className={styles.wrapper}>
          <ListDetailsPageTitle predictionList={predictionList} />
          <ListDetailsPageOrdersToggle predictionList={predictionList} />
        </div>

        <Space size="md" />

        <Row gutter={32}>
          <Col span={18}>
            <ListEntriesTableContainer predictionList={predictionList} />
          </Col>
          <Col span={6}>
            <ListDetailsPageSidePanel predictionList={predictionList} />
          </Col>
        </Row>
      </Content>
    </>
  )
}

export default ListDetailsPageContainer
