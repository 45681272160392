import { FC } from 'react'
import { Menu, Dropdown, MenuItemProps } from 'antd'
import { useTranslation, Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Text } from '@signifyd/components'
import { RuleResponse } from '@signifyd/http'
import DuplicatePolicyMenuItem from 'core/components/DuplicatePolicyMenuItem'
import EditPolicyMenuItem from 'core/components/EditPolicyMenuItem'
import { TableDropdownIcon } from 'core/components/Icons'
import { isPublishedPolicy } from 'core/constants'
import { SELECTED_RULE_ACTION } from 'pages/DashboardPage/DashboardPage.types'
import { OnClickRuleMenuItem, MENU_KEYS } from './RuleDropdown.types'
import styles from './RuleDropdown.less'

interface Props {
  rule: RuleResponse
  onClickEditRuleDetails: OnClickRuleMenuItem
  onClickManageSchedule: OnClickRuleMenuItem
  onClickDeletePolicy: OnClickRuleMenuItem
}

const RuleDropdown: FC<Props> = ({
  rule,
  onClickEditRuleDetails,
  onClickManageSchedule,
  onClickDeletePolicy,
}) => {
  const { t } = useTranslation()
  const handleMenuClick: MenuItemProps['onClick'] = ({ domEvent }): void => {
    domEvent.stopPropagation()
  }

  const ruleHasSchedule = rule.scheduleStart || rule.scheduleEnd
  const isPublished = isPublishedPolicy(rule)

  const getMoveRuleLink = (selectedRuleAction: SELECTED_RULE_ACTION): string =>
    `/policies/publish/${rule.teamId}/${rule.checkpoint}?selectedRuleId=${rule.ruleId}&selectedRuleAction=${selectedRuleAction}`

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key={MENU_KEYS.ViewSummary} data-test-id="viewSummaryMenuItem">
        <Link to={`/policies/summary/${rule.ruleId}`}>
          {t('ruleDashboardPage.ruleDropdown.viewSummary')}
        </Link>
      </Menu.Item>

      <Menu.Item
        key={MENU_KEYS.EditRuleDetails}
        data-test-id={`editRuleDetailsMenuItem-${rule.ruleId}`}
        onClick={() => onClickEditRuleDetails(rule)}
      >
        {t('ruleCommon.dropdown.editDetails')}
      </Menu.Item>

      <EditPolicyMenuItem menuKey={MENU_KEYS.EditPolicy} policy={rule} />

      <DuplicatePolicyMenuItem
        policy={rule}
        data-test-id={`duplicatePolicyDetailsMenuItem-${rule.ruleId}`}
        menuKey={MENU_KEYS.DuplicatePolicy}
      />

      {!isPublished && (
        <Menu.Item
          key={MENU_KEYS.DeletePolicy}
          data-test-id={`deletePolicyDetailsMenuItem-${rule.ruleId}`}
          onClick={() => onClickDeletePolicy(rule)}
        >
          {t('ruleDashboardPage.ruleDropdown.deletePolicy')}
        </Menu.Item>
      )}

      {isPublished && (
        <Menu.Item
          key={MENU_KEYS.ManageSchedule}
          data-test-id={`manageScheduleMenuItem-${rule.ruleId}`}
          onClick={() => onClickManageSchedule(rule)}
        >
          {t(
            `ruleDashboardPage.ruleDropdown.${
              ruleHasSchedule ? 'manageSchedule' : 'addSchedule'
            }`
          )}
        </Menu.Item>
      )}

      <Menu.Divider className={styles.divider} />

      <Menu.Item
        key="PUBLISHING"
        disabled
        data-test-id="publishingDividerMenuItem"
      >
        {t('ruleDashboardPage.ruleDropdown.publishingDivider')}
      </Menu.Item>

      {!isPublished && (
        <Menu.Item
          key={MENU_KEYS.MoveToPublished}
          data-test-id={`moveToPublishedMenuItem-${rule.ruleId}`}
        >
          <Link to={getMoveRuleLink(SELECTED_RULE_ACTION.MOVE_TO_PUBLISHED)}>
            <Trans
              i18nKey="ruleDashboardPage.ruleDropdown.moveToPublished"
              components={{ bold: <Text weight="semibold" /> }}
            />
          </Link>
        </Menu.Item>
      )}

      {isPublished && (
        <Menu.Item
          key={MENU_KEYS.ChangeRank}
          data-test-id={`changeRankMenuItem-${rule.ruleId}`}
        >
          <Link to={getMoveRuleLink(SELECTED_RULE_ACTION.CHANGE_RANK)}>
            {t('ruleDashboardPage.ruleDropdown.changeRank')}
          </Link>
        </Menu.Item>
      )}

      {isPublished && (
        <Menu.Item
          key={MENU_KEYS.MoveToOthers}
          data-test-id={`moveToOthersMenuItem-${rule.ruleId}`}
        >
          <Link to={getMoveRuleLink(SELECTED_RULE_ACTION.MOVE_TO_OTHERS)}>
            <Trans
              i18nKey="ruleDashboardPage.ruleDropdown.moveToOthers"
              components={{ bold: <Text weight="semibold" /> }}
            />
          </Link>
        </Menu.Item>
      )}
    </Menu>
  )

  return (
    <Dropdown placement="bottomRight" trigger={['click']} overlay={menu}>
      <div
        data-test-id={`policyDropdownTrigger-${rule.ruleId}`}
        className={styles.iconWrapper}
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation()
        }}
      >
        <TableDropdownIcon />
      </div>
    </Dropdown>
  )
}

export default RuleDropdown
