import { FileSearchOutlined } from '@ant-design/icons'
import {
  ExternalLink,
  getCaseSearchUrl,
  getOrdersUrl,
} from '@signifyd/components'
import { CHECKPOINT } from '@signifyd/http'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './ViewAffectedOrdersButton.less'

interface Props {
  policyId: number
  checkpoint: CHECKPOINT
  dateRange: [string, string]
  isPowerSearchEnabled: boolean
}

export const getRedirectUrl = (
  policyId: number,
  checkpoint: CHECKPOINT,
  dateRange: [string, string],
  isPowerSearchEnabled: boolean
): string => {
  const baseUrl = isPowerSearchEnabled ? getCaseSearchUrl() : getOrdersUrl()

  if (checkpoint === CHECKPOINT.RETURN) {
    return `${baseUrl}?returnRecommendedActionRuleId=${policyId}&normalizedPurchaseCreatedAt=${dateRange}`
  }

  return `${baseUrl}?guaranteeRecommendedActionRuleId=${policyId}&recommendedActionRuleId=${policyId}&normalizedPurchaseCreatedAt=${dateRange.join(
    ','
  )}`
}

const ViewAffectedOrdersButton: FC<Props> = ({
  policyId,
  checkpoint,
  dateRange,
  isPowerSearchEnabled,
}) => {
  const { t } = useTranslation()

  const url = getRedirectUrl(
    policyId,
    checkpoint,
    dateRange,
    isPowerSearchEnabled
  )

  return (
    <ExternalLink url={url} className={styles.link}>
      <FileSearchOutlined />
      <span>
        {t(`ruleSummaryPage.policyReports.viewOrdersButton.${checkpoint}`)}
      </span>
    </ExternalLink>
  )
}

export default ViewAffectedOrdersButton
