import {
  EXPIRATION_DISPLAY_UNIT,
  EXPIRATION_TYPES,
  ListExpiration,
  PREDICTION_LIST_TYPE,
  PREDICTION_LIST_UPDATABLE_FIELD,
} from '@signifyd/http'

const secondsPerTimeUnit: Record<EXPIRATION_DISPLAY_UNIT, number> = {
  [EXPIRATION_TYPES.MINUTES]: 60,
  [EXPIRATION_TYPES.HOURS]: 3600,
  [EXPIRATION_TYPES.DAYS]: 86400,
  [EXPIRATION_TYPES.WEEKS]: 604800,
  [EXPIRATION_TYPES.MONTHS]: 2592000,
}

export const calculateDuration = (
  expirationSeconds: number,
  timeUnit: EXPIRATION_DISPLAY_UNIT
): { value: number; unit: EXPIRATION_TYPES } => {
  const value = expirationSeconds / secondsPerTimeUnit[timeUnit]

  return { value, unit: timeUnit }
}

export const convertExpirationToSeconds = (
  expirationInSeconds: number | undefined,
  expirationType: EXPIRATION_DISPLAY_UNIT
): number => {
  if (!expirationInSeconds || !expirationType) {
    return 0
  }

  return expirationInSeconds * secondsPerTimeUnit[expirationType]
}

export const getExpirationTypeUpdatableField = (
  expirationType: EXPIRATION_TYPES
): PREDICTION_LIST_UPDATABLE_FIELD => {
  if (expirationType === EXPIRATION_TYPES.ORDERS) {
    return PREDICTION_LIST_UPDATABLE_FIELD.MAX_ORDERS_PER_ENTRY
  }

  if (expirationType === EXPIRATION_TYPES.CHARGEBACKS) {
    return PREDICTION_LIST_UPDATABLE_FIELD.MAX_CHARGEBACKS_PER_ENTRY
  }

  return PREDICTION_LIST_UPDATABLE_FIELD.SECONDS_BEFORE_EXPIRATION_PER_ENTRY
}

export interface ExpirationDisplayDetails {
  type: EXPIRATION_TYPES
  value: number
}

export const getExpirations = (
  expiration: ListExpiration
): Array<ExpirationDisplayDetails> => {
  const expirationsArray: Array<ExpirationDisplayDetails> = []

  const { maxOrdersPerEntry, maxChargebacksPerEntry, timeBasedExpiration } =
    expiration

  if (maxChargebacksPerEntry) {
    expirationsArray.push({
      type: EXPIRATION_TYPES.CHARGEBACKS,
      value: maxChargebacksPerEntry,
    })
  }

  if (maxOrdersPerEntry) {
    expirationsArray.push({
      type: EXPIRATION_TYPES.ORDERS,
      value: maxOrdersPerEntry,
    })
  }

  if (timeBasedExpiration) {
    const calculatedDuration = calculateDuration(
      timeBasedExpiration.secondsBeforeExpirationPerEntry,
      timeBasedExpiration.expirationDisplayUnit
    )

    expirationsArray.push({
      value: calculatedDuration.value,
      type: calculatedDuration.unit,
    })
  }

  return expirationsArray
}

const timeUnitExpirationTypes = [
  EXPIRATION_TYPES.MINUTES,
  EXPIRATION_TYPES.HOURS,
  EXPIRATION_TYPES.DAYS,
  EXPIRATION_TYPES.WEEKS,
  EXPIRATION_TYPES.MONTHS,
]

const signifydExpirationTypes = [
  EXPIRATION_TYPES.ORDERS,
  EXPIRATION_TYPES.CHARGEBACKS,
]
export const expirationTypesPerListType: Record<
  PREDICTION_LIST_TYPE,
  Array<EXPIRATION_TYPES>
> = {
  [PREDICTION_LIST_TYPE.EMAIL]: [
    ...signifydExpirationTypes,
    ...timeUnitExpirationTypes,
  ],
  [PREDICTION_LIST_TYPE.DEVICE_ID]: [
    ...signifydExpirationTypes,
    ...timeUnitExpirationTypes,
  ],
  [PREDICTION_LIST_TYPE.IP_ADDRESS]: [
    ...signifydExpirationTypes,
    ...timeUnitExpirationTypes,
  ],
  [PREDICTION_LIST_TYPE.ITEM_ID]: timeUnitExpirationTypes,
  [PREDICTION_LIST_TYPE.CARD_BIN]: timeUnitExpirationTypes,
  [PREDICTION_LIST_TYPE.DISCOUNT_CODE]: timeUnitExpirationTypes,
  [PREDICTION_LIST_TYPE.PHONE_NUMBER]: [
    ...signifydExpirationTypes,
    ...timeUnitExpirationTypes,
  ],
  [PREDICTION_LIST_TYPE.ADDRESS]: [
    ...signifydExpirationTypes,
    ...timeUnitExpirationTypes,
  ],
  [PREDICTION_LIST_TYPE.EMAIL_DOMAIN]: timeUnitExpirationTypes,
  [PREDICTION_LIST_TYPE.MEMBERSHIP_ID]: [
    ...signifydExpirationTypes,
    ...timeUnitExpirationTypes,
  ],
  [PREDICTION_LIST_TYPE.ACCOUNT_NUMBER]: [
    ...signifydExpirationTypes,
    ...timeUnitExpirationTypes,
  ],
  [PREDICTION_LIST_TYPE.TAX_ID]: [
    ...signifydExpirationTypes,
    ...timeUnitExpirationTypes,
  ],
}

export const calculateExpirationUpdatableFields = (
  expiration: ExpirationDisplayDetails
): Partial<ListExpiration> => {
  const { value, type } = expiration

  const expirationTypeField = getExpirationTypeUpdatableField(type)

  if (
    type === EXPIRATION_TYPES.ORDERS ||
    type === EXPIRATION_TYPES.CHARGEBACKS
  ) {
    return {
      [expirationTypeField]: value,
    }
  }

  const secondsBeforeExpirationPerEntry = convertExpirationToSeconds(
    value,
    type
  )

  return {
    timeBasedExpiration: {
      secondsBeforeExpirationPerEntry,
      expirationDisplayUnit: type,
    },
  }
}
