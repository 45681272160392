import { FC, useState, useCallback } from 'react'
import { cond, constant, matches, stubTrue } from 'lodash'
import { Button, Modal } from 'antd'
import { useTranslation, Trans } from 'react-i18next'
import { RcFile } from 'antd/es/upload'
import {
  DUPLICATE_PREDICTION_LIST_ENTRY_ACTION,
  PredictionList,
} from '@signifyd/http'
import CloseListEntriesModalPopconfirm from 'core/components/CloseListEntriesModalPopconfirm'
import { SIG_MODAL_PROPS } from 'core/constants'
import ListEntriesForm from 'pages/ListDetailsPage/components/ListEntriesForm'
import ResolveDuplicateEntriesForm from 'pages/ListDetailsPage/components/ResolveDuplicateEntriesForm'
import { useStoreActions, useStoreState } from 'stores'
import {
  MAX_MANUAL_LIST_ENTRY_ITEMS,
  UPLOAD_ENTRIES_METHOD,
} from 'stores/predictionList'
import { useListDetailsPageQueryParams } from 'pages/ListDetailsPage/useListDetailsPageQueryParams'

export interface Props {
  onClose: () => void
  predictionList: PredictionList
  visible: boolean
}

const CreateListEntriesModal: FC<Props> = ({
  predictionList,
  visible,
  onClose,
}) => {
  const { t } = useTranslation()
  const [query, setQuery] = useListDetailsPageQueryParams()
  const isNewList = query.isNewList === 'true'

  const [uploadEntriesMethod, setUploadEntriesMethod] = useState<
    UPLOAD_ENTRIES_METHOD | undefined
  >()
  const [manualInputValue, setManualInputValue] = useState<Array<string>>([])
  const [csvFile, setCSVFile] = useState<RcFile | undefined>()
  const [duplicateEntryAction, setDuplicateEntryAction] = useState<
    DUPLICATE_PREDICTION_LIST_ENTRY_ACTION | undefined
  >()
  const [isPartialComplete, setIsPartialComplete] = useState(false)

  const {
    createPredictionListEntriesWithJSON,
    createPredictionListEntriesWithCSV,
    resetCreatePredictionListEntriesHTTPStatuses,
  } = useStoreActions((actions) => actions.predictionListEntriesModel)
  const {
    createPredictionListEntriesWithJSON: createWithJSONHTTPStatus,
    createPredictionListEntriesWithCSV: createWithCSVHTTPStatus,
  } = useStoreState((state) => state.predictionListEntriesModel.HTTPStatuses)

  const hasDuplicateEntries =
    createWithJSONHTTPStatus.hasDuplicateEntries ||
    createWithCSVHTTPStatus.hasDuplicateEntries

  const okButtonEnabled = cond([
    [
      matches({
        uploadEntriesMethod: UPLOAD_ENTRIES_METHOD.MANUAL,
        manualInputValueValid: true,
        hasDuplicateEntries: false,
      }),
      constant(true),
    ],
    [
      matches({
        uploadEntriesMethod: UPLOAD_ENTRIES_METHOD.CSV,
        csvValid: true,
        hasDuplicateEntries: false,
      }),
      constant(true),
    ],
    [
      matches({
        hasDuplicateEntries: true,
        duplicateEntryActionValid: true,
      }),
      constant(true),
    ],
    [stubTrue, constant(false)],
  ])({
    uploadEntriesMethod,
    manualInputValueValid:
      manualInputValue.length > 0 &&
      manualInputValue.length <= MAX_MANUAL_LIST_ENTRY_ITEMS,
    csvValid: !!csvFile,
    hasDuplicateEntries,
    duplicateEntryActionValid: !!duplicateEntryAction,
  })

  const resetState = useCallback((): void => {
    setUploadEntriesMethod(undefined)
    setManualInputValue([])
    setCSVFile(undefined)
    setDuplicateEntryAction(undefined)
    resetCreatePredictionListEntriesHTTPStatuses()

    if (isNewList) {
      setQuery({ teamId: query.teamId, isNewList: null })
    }
  }, [
    resetCreatePredictionListEntriesHTTPStatuses,
    isNewList,
    setQuery,
    query.teamId,
  ])

  const onUploadWithJSON = (
    values: Array<string>,
    duplicateEntryAction?: DUPLICATE_PREDICTION_LIST_ENTRY_ACTION
  ): void => {
    createPredictionListEntriesWithJSON({
      predictionListId: predictionList.id,
      type: predictionList.type,
      values,
      duplicateEntryAction,
    })
  }

  const onUploadWithCSV = (
    file: RcFile,
    duplicateEntryAction?: DUPLICATE_PREDICTION_LIST_ENTRY_ACTION
  ): void => {
    createPredictionListEntriesWithCSV({
      predictionListId: predictionList.id,
      type: predictionList.type,
      file,
      duplicateEntryAction,
    })
  }

  const onOk = (): void => {
    if (
      uploadEntriesMethod === UPLOAD_ENTRIES_METHOD.MANUAL &&
      okButtonEnabled
    ) {
      onUploadWithJSON(manualInputValue, duplicateEntryAction)
    } else if (uploadEntriesMethod === UPLOAD_ENTRIES_METHOD.CSV && csvFile) {
      onUploadWithCSV(csvFile, duplicateEntryAction)
    }
  }

  const onCancel = (): void => {
    if (!isPartialComplete) {
      onClose()
    }
  }

  const formTitleOptions = {
    type: t(
      `listDetailsPage.createListEntriesModal.descriptionListType.${predictionList.type}`
    ),
  }
  const formTitle = isNewList ? (
    <Trans
      i18nKey="listDetailsPage.createListEntriesModal.description.newList"
      components={{ em: <em /> }}
      values={{
        type: formTitleOptions.type,
      }}
    />
  ) : (
    t(
      'listDetailsPage.createListEntriesModal.description.existingList',
      formTitleOptions
    )
  )

  const form = hasDuplicateEntries ? (
    <ResolveDuplicateEntriesForm
      value={duplicateEntryAction}
      onChange={setDuplicateEntryAction}
    />
  ) : (
    <ListEntriesForm
      csvError={createWithCSVHTTPStatus.error}
      csvFile={csvFile}
      disabled={
        createWithJSONHTTPStatus.pending || createWithCSVHTTPStatus.pending
      }
      manualInputError={createWithJSONHTTPStatus.error}
      manualInputValue={manualInputValue}
      manualInputValueCountLimit={MAX_MANUAL_LIST_ENTRY_ITEMS}
      onChangeUploadEntriesMethod={setUploadEntriesMethod}
      onChangeManualInputValue={setManualInputValue}
      onChangeCSVFile={setCSVFile}
      onPartialEntry={setIsPartialComplete}
      predictionListType={predictionList.type}
      title={formTitle}
      uploadEntriesMethod={uploadEntriesMethod}
    />
  )

  return (
    <Modal
      {...SIG_MODAL_PROPS}
      okButtonProps={{
        disabled: !okButtonEnabled,
        loading:
          createWithJSONHTTPStatus.pending || createWithCSVHTTPStatus.pending,
      }}
      title={t('listDetailsPage.createListEntriesModal.title')}
      open={visible}
      afterClose={resetState}
      footer={
        <>
          <CloseListEntriesModalPopconfirm
            disabled={!isPartialComplete}
            onConfirm={onCancel}
          >
            <Button onClick={onCancel} type="link">
              {t('listDetailsPage.createListEntriesModal.cancelText')}
            </Button>
          </CloseListEntriesModalPopconfirm>
          <Button disabled={!okButtonEnabled} type="link" onClick={onOk}>
            {hasDuplicateEntries
              ? t('listDetailsPage.createListEntriesModal.okText.hasDuplicate')
              : t('listDetailsPage.createListEntriesModal.okText.default')}
          </Button>
        </>
      }
    >
      {form}
    </Modal>
  )
}

export default CreateListEntriesModal
