import { ColumnProps } from 'antd/lib/table'
import { useTranslation } from 'react-i18next'
import { PredictionList, PredictionListEntry } from '@signifyd/http'
import useUserTeams from 'core/hooks/useUserTeams'
import { useStoreActions, useStoreState } from 'stores'
import getColumns from './ListEntriesTable.config'

const useListEntriesColumnConfig = (
  predictionList: PredictionList
): Array<ColumnProps<PredictionListEntry>> => {
  const predictionListType = predictionList.type
  const { t } = useTranslation()
  const filters = useStoreState(
    (state) => state.predictionListEntriesModel.filters
  )
  const { userTimeZone } = useUserTeams()

  const { deletePredictionListEntries } = useStoreActions(
    (actions) => actions.predictionListEntriesModel
  )

  const columnConfig = getColumns({
    title: {
      value: t(
        `listDetailsPage.listEntriesTable.valueColTitleByListType.${predictionListType}`
      ),
      createdAt: t('listDetailsPage.listEntriesTable.columnTitle.createdAt'),
      createdByName: t(
        'listDetailsPage.listEntriesTable.columnTitle.createdByName'
      ),
    },
    orderBy: filters.orderBy ?? null,
    ascending: filters.ascending ?? null,
    onDeleteEntry: (entryId) => {
      deletePredictionListEntries({
        predictionListId: predictionList.id,
        ids: [entryId],
      })
    },
    userTimeZone,
  })

  return columnConfig
}

export default useListEntriesColumnConfig
