import { FC } from 'react'
import { Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { GET_RULES_ORDER_BY } from '@signifyd/http'
import { InputLabel } from '@signifyd/components'
import { POLICY_GROUP_TYPE } from 'pages/DashboardPage/DashboardPage.types'
import styles from './RuleTableFilters.less'

interface Props {
  policyGroupType: POLICY_GROUP_TYPE
  orderByValue: GET_RULES_ORDER_BY
  onChangeOrderBy: (orderBy: GET_RULES_ORDER_BY) => void
}

const RuleTableFilters: FC<Props> = ({
  policyGroupType,
  orderByValue,
  onChangeOrderBy,
}) => {
  const { t } = useTranslation()

  const publishedOptions =
    policyGroupType === POLICY_GROUP_TYPE.published
      ? [
          {
            label: t('ruleDashboardPage.ruleTable.filters.options.rank'),
            value: GET_RULES_ORDER_BY.SORT_RANK,
          },
        ]
      : []

  return (
    <div>
      <InputLabel>
        {t('ruleDashboardPage.ruleTable.filters.sortByLabel')}
      </InputLabel>
      <Select
        value={orderByValue}
        className={styles.select}
        onChange={onChangeOrderBy}
        options={[
          ...publishedOptions,
          {
            label: t('ruleDashboardPage.ruleTable.filters.options.hits'),
            value: GET_RULES_ORDER_BY.SORT_HIT_COUNT,
          },
          {
            label: t('ruleDashboardPage.ruleTable.filters.options.createdDate'),
            value: GET_RULES_ORDER_BY.SORT_CREATED_AT,
          },
          {
            label: t('ruleDashboardPage.ruleTable.filters.options.startDate'),
            value: GET_RULES_ORDER_BY.SORT_SCHEDULE_START,
          },
          {
            label: t('ruleDashboardPage.ruleTable.filters.options.endDate'),
            value: GET_RULES_ORDER_BY.SORT_SCHEDULE_END,
          },
        ]}
      />
    </div>
  )
}

export default RuleTableFilters
