import { FC, ReactNode } from 'react'
import { Radio, Upload } from 'antd'
import { RcFile } from 'antd/es/upload'
import { useTranslation } from 'react-i18next'
import { Space, Text } from '@signifyd/components'
import { PREDICTION_LIST_TYPE } from '@signifyd/http'
import { nanoid } from 'nanoid'
import FormError from 'core/components/FormError'
import { UPLOAD_ENTRIES_METHOD } from 'stores/predictionList'
import ManualEntriesForm from './ManualEntriesForm'

const { Dragger } = Upload

export interface Props {
  csvFile?: RcFile
  csvError?: string
  disabled?: boolean
  manualInputError?: string
  manualInputValue: Array<string>
  manualInputValueCountLimit: number
  onChangeCSVFile: (param?: RcFile) => void
  onChangeManualInputValue: (param: Array<string>) => void
  onChangeUploadEntriesMethod: (param: UPLOAD_ENTRIES_METHOD) => void
  onPartialEntry: (value: boolean) => void
  predictionListType: PREDICTION_LIST_TYPE
  title: ReactNode
  uploadEntriesMethod?: UPLOAD_ENTRIES_METHOD
}

const ListEntriesForm: FC<Props> = ({
  csvError,
  csvFile,
  disabled,
  manualInputError,
  manualInputValue,
  manualInputValueCountLimit,
  onChangeCSVFile,
  onChangeManualInputValue,
  onChangeUploadEntriesMethod,
  onPartialEntry,
  predictionListType,
  title,
  uploadEntriesMethod,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Text data-test-id="listEntriesForm-title" size="sm" weight="semibold">
        {title}
      </Text>
      <Space size="md" />
      <Radio.Group
        disabled={disabled}
        onChange={(e) => onChangeUploadEntriesMethod(e.target.value)}
        value={uploadEntriesMethod}
      >
        <Radio
          value={UPLOAD_ENTRIES_METHOD.MANUAL}
          data-test-id="radioValueManual"
        >
          {t('listDetailsPage.listEntriesForm.uploadMethodLabel.STRING')}
        </Radio>
        <Radio value={UPLOAD_ENTRIES_METHOD.CSV} data-test-id="radioValueCsv">
          {t('listDetailsPage.listEntriesForm.uploadMethodLabel.CSV')}
        </Radio>
      </Radio.Group>
      {uploadEntriesMethod && <Space size="md" />}
      {uploadEntriesMethod === UPLOAD_ENTRIES_METHOD.MANUAL && (
        <ManualEntriesForm
          disabled={disabled}
          manualInputError={manualInputError}
          manualInputValue={manualInputValue}
          manualInputValueCountLimit={manualInputValueCountLimit}
          onChangeManualInputValue={onChangeManualInputValue}
          onPartialEntry={onPartialEntry}
          predictionListType={predictionListType}
        />
      )}

      {/* Don't disable the Dragger after a file is selected, it will hide the remove icon. */}
      {uploadEntriesMethod === UPLOAD_ENTRIES_METHOD.CSV && (
        <div data-test-id="csvDraggerContainer">
          <Text type="secondary" size="sm" block>
            {t('listDetailsPage.listEntriesForm.csvHelpHint')}
          </Text>
          <Space size="xs" />
          <Dragger
            disabled={disabled}
            name="file"
            accept=".csv"
            multiple={false}
            fileList={
              csvFile && [
                {
                  ...csvFile,
                  name: csvFile.name,
                  status: 'done',
                  uid: nanoid(),
                },
              ]
            }
            beforeUpload={(fileSelected) => {
              onChangeCSVFile(fileSelected)

              return false
            }}
            onRemove={() => {
              onChangeCSVFile()

              return false
            }}
          >
            <p>{t('listDetailsPage.listEntriesForm.csvDraggerContent')}</p>
          </Dragger>
          <FormError error={csvError} />
        </div>
      )}
    </>
  )
}

export default ListEntriesForm
