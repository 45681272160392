import { PredictionList } from '@signifyd/http'
import { FC } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { ListsIcon, Text } from '@signifyd/components'
import { useStoreState } from 'stores'
import StretchedNoResults from 'core/components/StretchedNoResults'
import ListEntriesTable from '../ListEntriesTable/ListEntriesTable'
import useCanRenderTable from './useCanRenderTable'

export interface Props {
  predictionList: PredictionList
}

/**
 * Wraps ListEntriesTable to avoid rendering the table if there are no entries yet
 */
const ListEntriesTableContainer: FC<Props> = ({ predictionList }) => {
  const { t } = useTranslation()
  const canRenderTable = useCanRenderTable()
  const { pending } = useStoreState(
    (state) =>
      state.predictionListEntriesModel.HTTPStatuses.listPredictionListEntries
  )

  const title = pending
    ? t('listDetailsPage.listEntriesTable.loading.title')
    : t('listDetailsPage.listEntriesTable.noListEntries.title')

  const description = pending ? (
    t('listDetailsPage.listEntriesTable.loading.description')
  ) : (
    <Trans
      i18nKey="listDetailsPage.listEntriesTable.noListEntries.description"
      components={{ bold: <Text weight="semibold" /> }}
    />
  )

  if (canRenderTable) {
    return <ListEntriesTable predictionList={predictionList} />
  }

  return (
    <StretchedNoResults
      title={title}
      description={description}
      icon={ListsIcon}
    />
  )
}

export default ListEntriesTableContainer
