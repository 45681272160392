import { ReactNode } from 'react'
import { ColumnProps } from 'antd/es/table'
import { RuleResponse, RULE_STATUS, CHECKPOINT_ACTION } from '@signifyd/http'
import { CheckpointActionTag } from '@signifyd/components'
import RuleRank from 'core/components/RuleRank'
import RuleStatusBadge from 'core/components/RuleStatusBadge'
import { POLICY_GROUP_TYPE } from 'pages/DashboardPage/DashboardPage.types'
import RuleDropdown, {
  OnClickRuleMenuItem,
} from 'pages/DashboardPage/containers/RuleDropdown'
import RuleName from 'core/containers/RuleName'
import RuleSchedule from 'core/containers/RuleSchedule'
import { checkpointActionIconMap } from 'core/constants'

const renderRuleRank = (rankInActiveRuleSet: number | null): ReactNode => (
  <RuleRank rank={rankInActiveRuleSet} />
)

const renderRuleName = (_text: any, rule: RuleResponse): ReactNode => (
  <RuleName rule={rule} />
)

const renderRuleAction = (
  recommendedAction: CHECKPOINT_ACTION,
  policy: RuleResponse
): ReactNode => (
  <CheckpointActionTag
    action={recommendedAction}
    icon={checkpointActionIconMap[policy.checkpoint]}
    hasTooltip
  />
)

const renderSchedule = (rule: RuleResponse): ReactNode => (
  <RuleSchedule rule={rule} />
)

const renderStatus = (ruleStatus: RULE_STATUS): ReactNode => (
  <RuleStatusBadge ruleStatus={ruleStatus} />
)

interface RenderDropdownIconProps {
  onClickEditRuleDetails: OnClickRuleMenuItem
  onClickManageSchedule: OnClickRuleMenuItem
  onClickDeletePolicyDetails: OnClickRuleMenuItem
}
const renderDropdownIcon =
  ({
    onClickEditRuleDetails,
    onClickManageSchedule,
    onClickDeletePolicyDetails,
  }: RenderDropdownIconProps) =>
  (rule: RuleResponse): ReactNode =>
    (
      <RuleDropdown
        rule={rule}
        onClickEditRuleDetails={onClickEditRuleDetails}
        onClickManageSchedule={onClickManageSchedule}
        onClickDeletePolicy={onClickDeletePolicyDetails}
      />
    )

interface GetColumnProps {
  titles: {
    rank: string
    name: string
    action: string
    hits: string
    schedule: string
    status: string
  }
  policyGroupType: POLICY_GROUP_TYPE
  onClickEditRuleDetails: OnClickRuleMenuItem
  onClickManageSchedule: OnClickRuleMenuItem
  onClickDeletePolicyDetails: OnClickRuleMenuItem
}
type ColumnConfig = ColumnProps<RuleResponse>

const getColumns = ({
  titles,
  policyGroupType,
  onClickEditRuleDetails,
  onClickManageSchedule,
  onClickDeletePolicyDetails,
}: GetColumnProps): Array<ColumnConfig> => {
  const rankCol: ColumnConfig = {
    title: titles.rank,
    key: 'rankInActiveRuleSet',
    dataIndex: 'rankInActiveRuleSet',
    render: renderRuleRank,
  }

  const nameCol: ColumnConfig = {
    title: titles.name,
    key: 'name',
    render: renderRuleName,
  }

  const actionCol: ColumnConfig = {
    title: titles.action,
    key: 'ruleRecommendedAction',
    dataIndex: ['ruleOutcome', 'ruleRecommendedAction'],
    render: renderRuleAction,
  }

  const hitsCol: ColumnConfig = {
    title: titles.hits,
    key: 'hitCount',
    dataIndex: 'hitCount',
  }

  const scheduleCol: ColumnConfig = {
    title: titles.schedule,
    key: 'schedule',
    render: renderSchedule,
  }

  const statusCol: ColumnConfig = {
    title: titles.status,
    key: 'status',
    dataIndex: 'ruleStatus',
    render: renderStatus,
  }

  const ruleSettingsCol: ColumnConfig = {
    key: 'dropdownIcon',
    render: renderDropdownIcon({
      onClickEditRuleDetails,
      onClickDeletePolicyDetails,
      onClickManageSchedule,
    }),
    align: 'right',
  }

  return policyGroupType === POLICY_GROUP_TYPE.others
    ? [nameCol, actionCol, hitsCol, statusCol, ruleSettingsCol]
    : [
        rankCol,
        nameCol,
        actionCol,
        hitsCol,
        scheduleCol,
        statusCol,
        ruleSettingsCol,
      ]
}

export default getColumns
