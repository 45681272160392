import { action, actionOn, computed, thunk, thunkOn } from 'easy-peasy'
import { fileDownload } from '@signifyd/utils'
import { i18nInstance } from '@signifyd/components'
import { AxiosError } from 'axios'
import {
  listPredictionListEntries as listPredictionListEntriesHTTP,
  downloadPredictionListEntries as downloadPredictionListEntriesHTTP,
  createPredictionListEntriesWithJson,
  createPredictionListEntriesWithForm,
  replacePredictionListEntriesWithJson,
  replacePredictionListEntriesWithForm,
  DUPLICATE_PREDICTION_LIST_ENTRY_ACTION,
  ListPredictionListEntriesParams,
  deletePredictionListEntries,
} from '@signifyd/http'
import { get } from 'lodash'
import { message } from 'antd'
import {
  DEFAULT_STATUS,
  START_STATUS,
  SUCCESS_STATUS,
  getHTTPErrorStatus,
} from 'core/http'
import {
  PredictionListEntriesState,
  PredictionListEntriesModel,
  PredictionListEntriesHTTPStatuses,
  CreateListEntriesHTTPStatusKey,
} from './predictionListEntries.types'
import {
  DEFAULT_STATE,
  GET_LIST_ENTRIES_LIMIT,
} from './predictionListEntries.store.state'

const mergeStateAndActions = (
  defaultState: PredictionListEntriesState
): PredictionListEntriesModel => ({
  ...defaultState,

  resetState: action((state) => {
    Object.assign(state, DEFAULT_STATE)
  }),

  setState: action((state, newState) => {
    Object.assign(state, newState)
  }),

  searchParams: computed(({ filters }) => {
    const searchParams: ListPredictionListEntriesParams = {
      offset:
        filters.page && filters.pageSize ? filters.page * filters.pageSize : 0,
      orderBy: filters.orderBy || undefined,
      limit: filters.pageSize || GET_LIST_ENTRIES_LIMIT,
      searchTerm: filters.searchTerm || undefined,
      isExpired: false,
    }

    if (filters.ascending) {
      searchParams.ascending = true
    } else if (filters.ascending === false) {
      searchParams.ascending = false
    }

    return searchParams
  }),

  hasAnyPredictionListEntries: computed(({ totalPredictionListEntries }) => {
    return totalPredictionListEntries !== null && totalPredictionListEntries > 0
  }),

  setSelectedEntryIds: action((state, selectedEntryIds) => {
    if (!state.predictionListEntries) {
      return
    }

    state.selectedEntryIds = selectedEntryIds
  }),

  listPredictionListEntries: thunk(
    (actions, { listId, filters: newFilters = {} }, { getState, fail }) => {
      const filters = { ...getState().filters, ...newFilters }

      actions.setState({ filters })

      const { searchParams } = getState()

      return listPredictionListEntriesHTTP(listId, { limit: 0 })
        .then((response) => {
          return response.data?.meta?.totalRows > 0
        })
        .then((hasAnyPredictionListEntries) => {
          return hasAnyPredictionListEntries
            ? listPredictionListEntriesHTTP(listId, searchParams)
            : Promise.resolve(undefined)
        })
        .then((response) => {
          if (!response) {
            return
          }

          actions.setState({
            predictionListEntries: response.data.data,
            totalPredictionListEntries: response.data.meta.totalRows,
            selectedEntryIds: [],
          })
        })
        .catch(fail)
    }
  ),

  createPredictionListEntriesWithJSON: thunk(
    (
      _actions,
      {
        predictionListId,
        duplicateEntryAction = DUPLICATE_PREDICTION_LIST_ENTRY_ACTION.UNKNOWN,
        values,
        type,
      },
      { fail }
    ) => {
      return createPredictionListEntriesWithJson(predictionListId, {
        duplicateEntryAction,
        values,
        type,
      }).catch(fail)
    }
  ),

  createPredictionListEntriesWithCSV: thunk(
    (
      _actions,
      {
        predictionListId,
        duplicateEntryAction = DUPLICATE_PREDICTION_LIST_ENTRY_ACTION.UNKNOWN,
        file,
        type,
      },
      { fail }
    ) => {
      const form = new FormData()

      form.append('file', file)
      form.append('type', type)
      form.append('duplicateEntryAction', duplicateEntryAction)

      return createPredictionListEntriesWithForm(predictionListId, form).catch(
        fail
      )
    }
  ),

  resetCreatePredictionListEntriesHTTPStatuses: action((state) => {
    state.HTTPStatuses.createPredictionListEntriesWithJSON = {
      ...DEFAULT_STATUS,
      hasDuplicateEntries: false,
    }
    state.HTTPStatuses.createPredictionListEntriesWithCSV = {
      ...DEFAULT_STATUS,
      hasDuplicateEntries: false,
    }
  }),

  replacePredictionListEntriesWithJSON: thunk(
    (_actions, { predictionListId, values, type }, { fail }) => {
      return replacePredictionListEntriesWithJson(predictionListId, {
        values,
        type,
      }).catch(fail)
    }
  ),

  replacePredictionListEntriesWithCSV: thunk(
    (_actions, { predictionListId, file, type }, { fail }) => {
      const form = new FormData()

      form.append('file', file)
      form.append('type', type)

      return replacePredictionListEntriesWithForm(predictionListId, form).catch(
        fail
      )
    }
  ),

  deletePredictionListEntries: thunk(
    (actions, { predictionListId, ids }, { getState, fail }) => {
      return deletePredictionListEntries(predictionListId, {
        listEntryIds: ids,
      })
        .then(() => {
          const {
            predictionListEntries,
            totalPredictionListEntries,
            selectedEntryIds,
          } = getState()

          message.success(
            i18nInstance.t('listCommon.successMessage.deleteListEntries', {
              count: ids.length,
            })
          )

          if (!predictionListEntries) {
            return Promise.resolve()
          }
          const idSet = new Set(ids)
          const remainingEntries = predictionListEntries.filter(
            (entry) => !idSet.has(entry.id)
          )
          const newSelectedEntryIds = selectedEntryIds.filter(
            (id) => !idSet.has(id)
          )

          return actions.setState({
            predictionListEntries: remainingEntries,
            totalPredictionListEntries: totalPredictionListEntries
              ? totalPredictionListEntries - ids.length
              : 0,
            selectedEntryIds: newSelectedEntryIds,
          })
        })
        .catch(fail)
    }
  ),

  downloadPredictionListEntries: thunk(
    (_actions, predictionListId, { fail }) => {
      return downloadPredictionListEntriesHTTP(predictionListId)
        .then((response) => {
          const contentDisposition = get(
            response,
            'headers["content-disposition"]'
          )

          const filename = contentDisposition
            ? contentDisposition.replace(/^attachment; filename="(.+)"$/, '$1')
            : 'listEntries.csv'

          fileDownload(response.data, filename, 'text/csv')
        })
        .catch(fail)
    }
  ),

  // TODO FET-1812 https://signifyd.atlassian.net/browse/FET-1824
  // this is a bad pattern, deprecate and replace with either react-query or just calling the function and handling errors inside a thunk
  onHTTP: actionOn(
    (actions) => [
      actions.listPredictionListEntries.startType,
      actions.listPredictionListEntries.successType,
      actions.listPredictionListEntries.failType,
      actions.replacePredictionListEntriesWithJSON.startType,
      actions.replacePredictionListEntriesWithJSON.successType,
      actions.replacePredictionListEntriesWithJSON.failType,
      actions.replacePredictionListEntriesWithCSV.startType,
      actions.replacePredictionListEntriesWithCSV.successType,
      actions.replacePredictionListEntriesWithCSV.failType,
      actions.deletePredictionListEntries.startType,
      actions.deletePredictionListEntries.successType,
      actions.deletePredictionListEntries.failType,
      actions.downloadPredictionListEntries.startType,
      actions.downloadPredictionListEntries.successType,
      actions.downloadPredictionListEntries.failType,
    ],
    (state, target) => {
      const [
        GET_LIST_ENTRIES_START,
        GET_LIST_ENTRIES_SUCCESS,
        GET_LIST_ENTRIES_FAILED,
        REPLACE_LIST_ENTRIES_JSON_START,
        REPLACE_LIST_ENTRIES_JSON_SUCCESS,
        REPLACE_LIST_ENTRIES_JSON_FAILED,
        REPLACE_LIST_ENTRIES_CSV_START,
        REPLACE_LIST_ENTRIES_CSV_SUCCESS,
        REPLACE_LIST_ENTRIES_CSV_FAILED,
        DELETE_LIST_ENTRIES_START,
        DELETE_LIST_ENTRIES_SUCCESS,
        DELETE_LIST_ENTRIES_FAILED,
        DOWNLOAD_LIST_ENTRIES_START,
        DOWNLOAD_LIST_ENTRIES_SUCCESS,
        DOWNLOAD_LIST_ENTRIES_FAILED,
      ] = target.resolvedTargets

      const handleError = (
        fallbackMessage: string,
        HTTPType: keyof Omit<
          PredictionListEntriesHTTPStatuses,
          CreateListEntriesHTTPStatusKey
        >
      ): void => {
        const status = getHTTPErrorStatus(
          target.error as AxiosError,
          fallbackMessage
        )

        state.HTTPStatuses[HTTPType] = status
      }

      switch (target.type) {
        case GET_LIST_ENTRIES_START:
          state.HTTPStatuses.listPredictionListEntries = START_STATUS
          break
        case GET_LIST_ENTRIES_SUCCESS:
          state.HTTPStatuses.listPredictionListEntries = SUCCESS_STATUS
          break
        case GET_LIST_ENTRIES_FAILED:
          handleError(
            i18nInstance.t('listCommon.fallbackErrorMessage.getListEntries'),
            'listPredictionListEntries'
          )
          break
        case REPLACE_LIST_ENTRIES_JSON_START:
          state.HTTPStatuses.replacePredictionListEntriesWithJSON = START_STATUS
          break
        case REPLACE_LIST_ENTRIES_JSON_SUCCESS:
          state.HTTPStatuses.replacePredictionListEntriesWithJSON =
            SUCCESS_STATUS
          message.success(
            i18nInstance.t('listCommon.successMessage.replaceListEntries')
          )
          break
        case REPLACE_LIST_ENTRIES_JSON_FAILED:
          handleError(
            i18nInstance.t(
              'listCommon.fallbackErrorMessage.replaceListEntries'
            ),
            'replacePredictionListEntriesWithJSON'
          )
          break
        case REPLACE_LIST_ENTRIES_CSV_START:
          state.HTTPStatuses.replacePredictionListEntriesWithCSV = START_STATUS
          break
        case REPLACE_LIST_ENTRIES_CSV_SUCCESS:
          state.HTTPStatuses.replacePredictionListEntriesWithCSV =
            SUCCESS_STATUS
          message.success(
            i18nInstance.t('listCommon.successMessage.replaceListEntries')
          )
          break
        case REPLACE_LIST_ENTRIES_CSV_FAILED:
          handleError(
            i18nInstance.t(
              'listCommon.fallbackErrorMessage.replaceListEntries'
            ),
            'replacePredictionListEntriesWithCSV'
          )
          break
        case DELETE_LIST_ENTRIES_START:
          state.HTTPStatuses.deletePredictionListEntries = START_STATUS
          break
        case DELETE_LIST_ENTRIES_SUCCESS:
          state.HTTPStatuses.deletePredictionListEntries = SUCCESS_STATUS
          break
        case DELETE_LIST_ENTRIES_FAILED: {
          const status = getHTTPErrorStatus(
            target.error as AxiosError,
            i18nInstance.t('listCommon.fallbackErrorMessage.deleteListEntries')
          )

          state.HTTPStatuses.deletePredictionListEntries = status
          message.error(status.error)
          break
        }
        case DOWNLOAD_LIST_ENTRIES_START:
          state.HTTPStatuses.downloadPredictionListEntries = START_STATUS
          break
        case DOWNLOAD_LIST_ENTRIES_SUCCESS:
          state.HTTPStatuses.downloadPredictionListEntries = SUCCESS_STATUS
          break
        case DOWNLOAD_LIST_ENTRIES_FAILED: {
          const status = getHTTPErrorStatus(
            target.error as AxiosError,
            i18nInstance.t(
              'listCommon.fallbackErrorMessage.downloadListEntries'
            )
          )

          state.HTTPStatuses.downloadPredictionListEntries = status
          message.error(status.error)
          break
        }

        default:
          break
      }
    }
  ),

  onCreateEntriesHTTP: actionOn(
    (actions) => [
      actions.createPredictionListEntriesWithJSON.startType,
      actions.createPredictionListEntriesWithJSON.successType,
      actions.createPredictionListEntriesWithJSON.failType,
      actions.createPredictionListEntriesWithCSV.startType,
      actions.createPredictionListEntriesWithCSV.successType,
      actions.createPredictionListEntriesWithCSV.failType,
    ],
    (state, target) => {
      const [
        CREATE_LIST_ENTRIES_JSON_START,
        CREATE_LIST_ENTRIES_JSON_SUCCESS,
        CREATE_LIST_ENTRIES_JSON_FAILED,
        CREATE_LIST_ENTRIES_CSV_START,
        CREATE_LIST_ENTRIES_CSV_SUCCESS,
        CREATE_LIST_ENTRIES_CSV_FAILED,
      ] = target.resolvedTargets

      const handleStart = (key: CreateListEntriesHTTPStatusKey): void => {
        state.HTTPStatuses[key] = {
          ...START_STATUS,
          hasDuplicateEntries: state.HTTPStatuses[key].hasDuplicateEntries,
        }
      }

      const handleSuccess = (key: CreateListEntriesHTTPStatusKey): void => {
        const entryIds = target.result.data

        state.HTTPStatuses[key] = {
          ...SUCCESS_STATUS,
          hasDuplicateEntries: state.HTTPStatuses[key].hasDuplicateEntries,
        }
        message.success(
          !entryIds?.length
            ? i18nInstance.t('listCommon.successMessage.noEntriesAdded')
            : i18nInstance.t('listCommon.successMessage.createListEntries', {
                count: entryIds.length,
              })
        )
      }

      const handleError = (key: CreateListEntriesHTTPStatusKey): void => {
        const axioxError = target.error as AxiosError
        const statusCode = axioxError.response?.status
        const invalidValues = (axioxError.response?.data as any)?.errors?.values

        if (statusCode === 409) {
          state.HTTPStatuses[key] = {
            success: false,
            pending: false,
            error: undefined,
            hasDuplicateEntries: true,
          }
        } else if (Array.isArray(invalidValues)) {
          const prefix = i18nInstance.t(
            'listCommon.fallbackErrorMessage.invalidListEntries'
          )

          state.HTTPStatuses[key] = {
            pending: false,
            success: false,
            error: `${prefix} ${invalidValues.join(', ')}`,
            hasDuplicateEntries: state.HTTPStatuses[key].hasDuplicateEntries,
          }
        } else {
          const status = getHTTPErrorStatus(
            axioxError,
            i18nInstance.t('listCommon.fallbackErrorMessage.createListEntries')
          )

          state.HTTPStatuses[key] = {
            ...status,
            hasDuplicateEntries: state.HTTPStatuses[key].hasDuplicateEntries,
          }
        }
      }

      switch (target.type) {
        case CREATE_LIST_ENTRIES_JSON_START:
          handleStart('createPredictionListEntriesWithJSON')
          break
        case CREATE_LIST_ENTRIES_CSV_START:
          handleStart('createPredictionListEntriesWithCSV')
          break
        case CREATE_LIST_ENTRIES_JSON_SUCCESS: {
          handleSuccess('createPredictionListEntriesWithJSON')
          break
        }
        case CREATE_LIST_ENTRIES_CSV_SUCCESS: {
          handleSuccess('createPredictionListEntriesWithCSV')
          break
        }
        case CREATE_LIST_ENTRIES_JSON_FAILED: {
          handleError('createPredictionListEntriesWithJSON')
          break
        }
        case CREATE_LIST_ENTRIES_CSV_FAILED: {
          handleError('createPredictionListEntriesWithCSV')
          break
        }
        default:
          break
      }
    }
  ),

  onUploadPredictionEntries: thunkOn(
    (actions) => [
      actions.createPredictionListEntriesWithJSON.successType,
      actions.createPredictionListEntriesWithCSV.successType,
      actions.replacePredictionListEntriesWithJSON.successType,
      actions.replacePredictionListEntriesWithCSV.successType,
    ],
    async (actions, target, { getState }) => {
      // should reset browser query string
      const filters = {
        ...DEFAULT_STATE.filters,
        searchTerm: getState().filters.searchTerm,
      }

      actions.setState({ filters })

      await actions.listPredictionListEntries({
        listId: target.payload.predictionListId,
      })
    }
  ),
})

export default mergeStateAndActions
