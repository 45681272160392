import { FC, useState } from 'react'
import { Radio, Tabs } from 'antd'
import { useTranslation } from 'react-i18next'
import { colorSlate, colorBondi, colorMarengo } from '@signifyd/colors'
import { Layout, PageSpinner, Space } from '@signifyd/components'
import { CHECKPOINT } from '@signifyd/http'
import { usePublishedRuleset } from 'core/hooks/usePublishedRuleSet/usePublishedRuleSet'
import PublishedRuleSetInfo from 'core/containers/PublishedRuleSetInfo'
import useUserTeams from 'core/hooks/useUserTeams'
import RuleCreationSuccessModal from 'pages/DashboardPage/containers/RuleSuccessModal'
import PolicyTable from 'pages/DashboardPage/containers/RuleTable'
import RuleTableFilters from 'pages/DashboardPage/containers/RuleTableFilters'
import { useDashboardPageFilters } from 'pages/DashboardPage/hooks/useDashboardPageFilters'
import useGetDashboardPolicies from 'pages/DashboardPage/queries/useGetDashboardPolicies'
import { POLICY_GROUP_TYPE } from 'pages/DashboardPage/DashboardPage.types'
import { getCheckpointTabsData } from 'pages/DashboardPage/DashboardPage.utils'
import styles from './DashboardContainer.less'
import RuleEditSuccessModal from '../RuleSuccessModal/RuleEditSuccessModal'

const { Header, Content } = Layout

const { TabPane } = Tabs
const TAB_BAR_STYLE = {
  borderBottom: 'none',
  marginBottom: 0,
}

const TAB_BAR_GUTTER_SPACING = 64

interface Props {
  selectedTeamCheckpoints: Array<CHECKPOINT>
  setCreateRuleModalVisible: (visible: boolean) => void
}

const DashboardContainer: FC<Props> = ({
  setCreateRuleModalVisible,
  selectedTeamCheckpoints,
}) => {
  const { t } = useTranslation()

  const [
    {
      showNewRules,
      teamId,
      checkpoint,
      publishedOrderBy,
      othersOrderBy,
      editedPolicyId,
    },
    setFilters,
  ] = useDashboardPageFilters()

  const [policyGroupType, setPolicyGroupType] = useState(
    POLICY_GROUP_TYPE.published
  )

  const { userTimeZone } = useUserTeams()

  const {
    data: { publishedCount, otherCount },
  } = useGetDashboardPolicies()

  const otherTabLabel = t('ruleDashboardPage.othersTab', {
    count: otherCount,
  })

  const publishedTabLabel = t('ruleDashboardPage.publishedTab', {
    count: publishedCount,
  })

  const policyTypeTabs = [
    {
      testId: 'published-tab',
      value: 'published',
      label: publishedTabLabel,
    },
    {
      testId: 'others-tab',
      value: 'others',
      label: otherTabLabel,
    },
  ]

  const getFontColor = (tabValue: string): string | undefined =>
    tabValue !== policyGroupType ? colorSlate : undefined

  const checkpointTabsData = getCheckpointTabsData()
  const filteredCheckpointsTabData = checkpointTabsData.filter(({ key }) =>
    selectedTeamCheckpoints?.includes(key)
  )

  const { data: ruleSet } = usePublishedRuleset({
    teamId,
    checkpoint,
  })

  if (!teamId || !checkpoint || !selectedTeamCheckpoints) {
    return <PageSpinner />
  }

  return (
    <>
      <Header
        theme="light"
        marginBottom="none"
        paddingTop="none"
        paddingBottom="none"
      >
        <div className={styles.tabs}>
          <Tabs
            activeKey={checkpoint}
            onTabClick={(activeKey) => {
              const checkpoint = activeKey as CHECKPOINT

              setFilters({ checkpoint })
            }}
            tabBarStyle={TAB_BAR_STYLE}
            tabBarGutter={TAB_BAR_GUTTER_SPACING}
          >
            {filteredCheckpointsTabData.map(({ Icon, key, label }) => (
              <TabPane
                key={key}
                tab={
                  <div
                    className={styles.checkpointTab}
                    data-test-id={`${key.toLowerCase()}Tab`}
                  >
                    <Icon
                      className={styles.icon}
                      fill={key === checkpoint ? colorBondi : colorMarengo}
                    />
                    {label}
                  </div>
                }
              />
            ))}
          </Tabs>
        </div>
      </Header>

      <Header paddingTop="lg" paddingBottom="lg" marginBottom="none">
        <div className={styles.headerWrapper}>
          <RuleTableFilters
            policyGroupType={policyGroupType}
            orderByValue={
              policyGroupType === POLICY_GROUP_TYPE.published
                ? publishedOrderBy
                : othersOrderBy
            }
            onChangeOrderBy={(orderBy) => {
              const key =
                policyGroupType === POLICY_GROUP_TYPE.published
                  ? 'publishedOrderBy'
                  : 'othersOrderBy'

              setFilters({ [key]: orderBy })
            }}
          />

          <Radio.Group
            className={styles.radio}
            onChange={(e) => {
              const { value: policyGroupType } = e.target

              setPolicyGroupType(policyGroupType)
            }}
            value={policyGroupType}
            buttonStyle="outline"
          >
            {policyTypeTabs.map((tab) => {
              const color = getFontColor(tab.value)

              return (
                <Radio.Button
                  style={{ color }}
                  key={tab.value}
                  data-test-id={tab.testId}
                  value={tab.value}
                >
                  {tab.label}
                </Radio.Button>
              )
            })}
          </Radio.Group>

          <PublishedRuleSetInfo
            style={{ justifySelf: 'flex-end' }}
            ruleSet={ruleSet ?? null}
            userTimeZone={userTimeZone}
          />
        </div>
      </Header>
      <Content>
        <PolicyTable policyGroupType={policyGroupType} />
        <Space size="lg" />
      </Content>

      {showNewRules === 'true' && (
        <RuleCreationSuccessModal
          checkpoint={checkpoint}
          teamId={teamId.toString()}
          onClickCreateRule={() => {
            setFilters({ showNewRules: undefined })
            setCreateRuleModalVisible(true)
          }}
          onClickLater={() => setFilters({ showNewRules: undefined })}
        />
      )}

      {editedPolicyId && (
        <RuleEditSuccessModal
          checkpoint={checkpoint}
          teamId={teamId.toString()}
          onClickLater={() => setFilters({ editedPolicyId: undefined })}
          editedPolicyId={editedPolicyId}
          onClickCreateRule={() => {
            setFilters({ editedPolicyId: undefined })
            setCreateRuleModalVisible(true)
          }}
        />
      )}
    </>
  )
}

export default DashboardContainer
