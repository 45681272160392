import { Action, ActionOn, Computed, Thunk, ThunkOn } from 'easy-peasy'
import {
  PredictionListEntry,
  PREDICTION_LIST_TYPE,
  CreatePredictionListEntriesPayload,
  ReplacePredictionListEntriesPayload,
  DUPLICATE_PREDICTION_LIST_ENTRY_ACTION,
  PREDICTION_LIST_ENTRY_SORT_BY,
  ListPredictionListEntriesParams,
} from '@signifyd/http'
import { HTTPStatus } from 'core/http'

export enum UPLOAD_ENTRIES_METHOD {
  MANUAL = 'MANUAL',
  CSV = 'CSV',
}

export interface UploadEntriesWithJSONPayload
  extends Omit<CreatePredictionListEntriesPayload, 'duplicateEntryAction'> {
  predictionListId: number
  duplicateEntryAction?: DUPLICATE_PREDICTION_LIST_ENTRY_ACTION
}

export interface UploadEntriesWithCSVPayload {
  predictionListId: number
  file: File
  type: PREDICTION_LIST_TYPE
  duplicateEntryAction?: DUPLICATE_PREDICTION_LIST_ENTRY_ACTION
}

export interface CreateEntriesHTTPStatus extends HTTPStatus {
  hasDuplicateEntries: boolean
}

export interface ReplaceEntriesWithJSONPayload
  extends ReplacePredictionListEntriesPayload {
  predictionListId: number
}

export interface ReplaceEntriesWithCSVPayload
  extends Omit<ReplacePredictionListEntriesPayload, 'values'> {
  predictionListId: number
  file: File
}

export interface PredictionListEntriesHTTPStatuses {
  listPredictionListEntries: HTTPStatus
  createPredictionListEntriesWithJSON: CreateEntriesHTTPStatus
  createPredictionListEntriesWithCSV: CreateEntriesHTTPStatus
  replacePredictionListEntriesWithJSON: HTTPStatus
  replacePredictionListEntriesWithCSV: HTTPStatus
  deletePredictionListEntries: HTTPStatus
  downloadPredictionListEntries: HTTPStatus
}

export type CreateListEntriesHTTPStatusKey =
  | 'createPredictionListEntriesWithJSON'
  | 'createPredictionListEntriesWithCSV'

export interface GetListEntriesFilters {
  page?: number | null
  pageSize?: number | null
  ascending?: boolean | null
  orderBy?: PREDICTION_LIST_ENTRY_SORT_BY | null
  searchTerm?: string | null
}

export interface PredictionListEntriesState {
  predictionListEntries: Array<PredictionListEntry> | null
  totalPredictionListEntries: number | null
  selectedEntryIds: Array<number>
  filters: GetListEntriesFilters
  HTTPStatuses: PredictionListEntriesHTTPStatuses
}

export interface PredictionListEntriesModel extends PredictionListEntriesState {
  searchParams: Computed<
    PredictionListEntriesModel,
    ListPredictionListEntriesParams
  >
  hasAnyPredictionListEntries: Computed<PredictionListEntriesModel, boolean>

  resetState: Action<PredictionListEntriesModel>
  setState: Action<
    PredictionListEntriesModel,
    Partial<PredictionListEntriesState>
  >
  setSelectedEntryIds: Action<PredictionListEntriesModel, Array<number>>

  listPredictionListEntries: Thunk<
    PredictionListEntriesModel,
    {
      listId: number | string
      filters?: Partial<GetListEntriesFilters>
    }
  >
  createPredictionListEntriesWithJSON: Thunk<
    PredictionListEntriesModel,
    UploadEntriesWithJSONPayload
  >
  createPredictionListEntriesWithCSV: Thunk<
    PredictionListEntriesModel,
    UploadEntriesWithCSVPayload
  >
  resetCreatePredictionListEntriesHTTPStatuses: Action<PredictionListEntriesModel>
  replacePredictionListEntriesWithJSON: Thunk<
    PredictionListEntriesModel,
    ReplaceEntriesWithJSONPayload
  >
  replacePredictionListEntriesWithCSV: Thunk<
    PredictionListEntriesModel,
    ReplaceEntriesWithCSVPayload
  >
  deletePredictionListEntries: Thunk<
    PredictionListEntriesModel,
    {
      predictionListId: number | string
      ids: Array<number>
    }
  >
  downloadPredictionListEntries: Thunk<
    PredictionListEntriesModel,
    number | string
  >

  onHTTP: ActionOn<PredictionListEntriesModel>
  onCreateEntriesHTTP: ActionOn<PredictionListEntriesModel>

  onUploadPredictionEntries: ThunkOn<PredictionListEntriesModel>
}
