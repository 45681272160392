import { RuleResponse } from '@signifyd/http'
import { Menu, Tooltip, MenuItemProps } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useStoreActions } from 'stores'

const MAX_NAME_LENGTH = 80
const NAME_SUFFIX = ' (copy)'

interface Props {
  policy: RuleResponse
  menuKey: string
}

const DuplicatePolicyMenuItem: FC<Props> = ({ policy, menuKey, ...props }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { createPolicy } = useStoreActions((actions) => actions.ruleModel)

  const handleClick: MenuItemProps['onClick'] = ({ domEvent }): void => {
    domEvent.stopPropagation()

    const {
      checkpoint,
      description,
      name,
      ruleOutcome,
      ruleType,
      targetDispositions,
      teamId,
      predicate,
    } = policy

    // Add (copy) to name, and ensure it is max 80 characters after adding
    const copyName = `${name.slice(
      0,
      MAX_NAME_LENGTH - NAME_SUFFIX.length
    )}${NAME_SUFFIX}`

    createPolicy({
      policy: {
        checkpoint,
        description,
        name: copyName,
        ruleOutcome,
        ruleType,
        targetDispositions,
        teamId,
        predicate,
      },
      navigate,
    })
  }

  return (
    <Menu.Item
      {...props} // passing through antd props from menu
      key={menuKey}
      data-test-id="duplicatePolicyDetailsMenuItem"
      onClick={handleClick}
      disabled={!policy.canModifyPolicy}
      data-analytics-id="duplicate-policy-menu-item"
    >
      <Tooltip
        title={
          !policy.canModifyPolicy && t('ruleCommon.dropdown.duplicateTooltip')
        }
      >
        {t('ruleCommon.dropdown.duplicate')}
      </Tooltip>
    </Menu.Item>
  )
}

export default DuplicatePolicyMenuItem
